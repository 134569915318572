import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../Loader";
import axiosInstance from "../../../interceptor/axiosInstance";
import { toast } from "react-toastify";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import CallActionButton from "../../../callActionButton/CallActionButton";
import CallActionFavButton from "../../../callActionButton/callActionFavButton";

const API_URL = process.env.REACT_APP_API_URL;

const FavouriteProperty = () => {
  moment.locale('en');
  const [propertyId, setpropertyId] = useState("");
  const [propertyType, setPropertyType] = useState([]);
  const [loading, setLoading] = useState("");
  const [fliterData, setFliterData] = useState([]);
 
  const [open, setOpen] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
console.log(allProperties,"FAV");
  const [selectedBuildingTypeId, setSelectedBuildingTypeId] = useState("");
  const [selectedBuildingTypeName, setSelectedBuildingTypeName] = useState("");

  const [searchText, setSearchText] = useState("");

  // Handle Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [buildingType, setBuildingType] = useState([]);
  const [propertyTypeId, setpropertyTypeId] = useState("");
  const [propertyTypeName, setPropertyTypeName] = useState("");

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.delete(
        `${API_URL}/properties/favourite/${propertyId}/`
      );

      if (response.status === 204) {
        // The delete request was successful (status code 204)
        // You can perform any further actions here, e.g., update the UI
        setLoading(false);
      } else {
        // Handle other status codes or errors as needed
       
      }
    } catch (error) {
      // Handle network errors or exceptions
      toast.error(' Please Contact Administrator');
    }
    setpropertyId("");
    getAllProperties();
    toast.success("Property Removed Successfully From Favourite");
  };

  /* ****************api for property list ************* */

  const getAllProperties = async () => {
    // setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/properties/dashboard/favourite/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setFliterData(responseData);
        setAllProperties(responseData);
        setOpen(new Array(setFliterData.length).fill(false));
        // setLoading(false);
      } else {
    
      }
    } catch (err) {
      toast.error(' Please Contact Administrator');
    }
  };
  /* ****************api for building type ************* */

  const getBuildType = async () => {
    // setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/properties/building/types/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setBuildingType(responseData);
        // setLoading(false);
      } else {
       
      }
    } catch (err) {
      toast.error(' Please Contact Administrator');
    }
  };
  /* ****************api for property type ************* */

  const getPropertyType = async () => {
    const idid = selectedBuildingTypeId;
    
    // setLoading(true);
    if (selectedBuildingTypeId !== null) {
      try {
        const response = await axiosInstance.get(
          `${API_URL}/properties/property/types/?building_type=${selectedBuildingTypeId}`
        );
        if ((response.status = 200)) {
          const responseData = response.data;
          setPropertyType(responseData);
          // setLoading(false);
        } else {
          
        }
      } catch (err) {
        toast.error(' Please Contact Administrator');
      }
    } else {
      setFliterData(allProperties);
    }
  };
  useEffect(() => {
    if (searchText !== null) {
      // setLoading(true);
      const res = allProperties.filter((item) => {
        return searchText.toLowerCase() === ""
          ? item
          : item?.get_property_city?.toLowerCase().includes(searchText) ||
          item?.get_property_name?.toLowerCase().includes(searchText) ||
          item?.get_property_state?.toLowerCase().includes(searchText) ||
          item?.get_property_street_address?.toLowerCase().includes(searchText);

      });
      if (res.length > 0) {
        setFliterData(res);
        // setLoading(false);
      } else {
        setFliterData(allProperties);
        // setLoading(false);
      }
    }
  }, [searchText, allProperties]);
  useEffect(() => {
    if (selectedBuildingTypeId) {
      // setLoading(true);
      const newres = allProperties.filter((item) => {
        return selectedBuildingTypeName === ""
          ? allProperties
          : item?.get_property_building_type?.includes(selectedBuildingTypeName);
      });
     
      if (newres.length > 0) {
        setFliterData(newres);
        // setLoading(false);
        //   setCategoryData(newres)
      }
    } else {
      setFliterData(allProperties);
    }
  }, [selectedBuildingTypeId, allProperties]);

  useEffect(() => {
    if (propertyTypeId) {
      // setLoading(true);
      const newres = allProperties.filter((item) =>
        item?.get_property_property_type?.includes(propertyTypeName)
      );

      if (newres.length > 0) {
        setFliterData(newres);
        // setLoading(false);
      } else {
        // Handle the case when propertyTypeId doesn't match any properties
        // setLoading(false);
        toast.error("No matching properties found");
        // setFliterData([]); // or set it to an empty array or handle it as needed
      }
    }
  }, [propertyTypeId, allProperties]);

  useEffect(() => {
    getAllProperties();
    getBuildType();
  }, []);
  useEffect(() => {
    if (selectedBuildingTypeId) {
      getPropertyType();
    }
  }, [selectedBuildingTypeId]);
  const ResetButton = () => {
    // setLoading(true)
    window.location.reload()


  }
  return (
    <>
      {loading ? <Loader /> : null}
      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="d-flex flex-wrap flex-md-nowrap mb-6">
            <div className="mr-0 mr-md-auto">
              <h2 className="mb-0 text-heading fs-22 lh-15">
                Favourite Properties
                <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">
                  {fliterData?.length}
                </span>
              </h2>
            </div>
            {/* {fliterData?.length > 0 ? */}
            <div className="form-inline justify-content-md-end mx-n2">
              <div className="p-2">
                <div className="input-group input-group-lg bg-white border">
                  <div className="input-group-prepend">
                    <button
                      className=" btn pr-0 shadow-none border-0"
                      type="button"
                    >
                      <i className="far fa-search mr-3"></i>
                    </button>
                  </div>
                  <input
                    type="text"
                    className="form-control bg-transparent border-0 shadow-none text-body "
                    placeholder="Enter Property Name & Location"
                    value={searchText}
                    name="search"
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="input-group input-group-lg bg-white border-0 ml-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-transparent letter-spacing-093 border-0 pr-0">
                      <i className="far fa-align-left mr-2"></i>Sort By
                      Category:
                    </span>
                  </div>
                  <select
                    className="ml-2 form-control bg-transparent pl-0 selectpicker d-flex align-items-center sortby"
                    name="sort-by"
                    data-bs-style="bg-transparent px-1 py-0 lh-1 font-weight-600 text-body"
                    id="buildingTypeId"

                    onChange={(e) => {
                      setSelectedBuildingTypeId(e?.target?.value);
                      setSelectedBuildingTypeName(e?.target?.options[e?.target?.selectedIndex]?.getAttribute('name'));
                    }}
                  >
                    <option value="">All Properties</option>
                    {buildingType?.map((item) => {
                      return <option key={item?.id} name={item?.name} value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              {/* {propertyTypeData?.length > 0 ? (<> */}
              <div className="p-2">
                <div className="input-group input-group-lg bg-white border-0 ml-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-transparent letter-spacing-093 border-0 pr-0">
                      <i className="far fa-align-left mr-2"></i>Sort By Type:
                    </span>
                  </div>
                  <select
                    className="ml-2 form-control bg-transparent pl-0 selectpicker d-flex align-items-center sortby"
                    name="sort-by"
                    data-bs-style="bg-transparent px-1 py-0 lh-1 font-weight-600 text-body"
                    id="buildingTypeId"
                    onChange={(e) => {
                      setpropertyTypeId(e?.target?.value);
                      setPropertyTypeName(e?.target?.options[e?.target?.selectedIndex]?.getAttribute('name'));
                    }}

                  // value={multipleInput.categoryId}
                  >
                    <option>Select</option>

                    {propertyType?.map((item) => {
                      return <option key={item?.id} name={item?.name} value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="d-flex search-reset mb-3">
                <div className="ad-sr">
                  <button type="button" onClick={() => ResetButton()}
                    className="btn advanced-search bg-saffron text-white hover-grey width-re-10">
                    <img src="../images/reload.png" alt="Reload" />
                  </button>
                </div>
              </div>

              {/* </>) : null} */}
            </div>
            {/* : null} */}
          </div>
          <div className="table-responsive">
            <table className="table table-hover bg-white border rounded-lg">
              <thead className="thead-sm thead-black bg-blue text-white fs-18">
                <tr>
                  <th scope="col" className=" col-8 border-top-0 px-4 pt-5 pb-4">
                    Listing title
                  </th>
                  <th scope="col" className="border-top-0 pt-5 pb-4">
                    Date
                  </th>
                  <th scope="col" className="border-top-0 pt-5 pb-4 display-de-none">
                    Category
                  </th>
                  <th scope="col" className="border-top-0 pt-5 pb-4">
                    Action
                  </th>
                  {/* <th className=" text-align-center border-top-0 px-6 pt-5 pb-4">
                   Total Leads
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {fliterData?.length > 0 ? (
                  <>
                    {fliterData.map((item, index) => {
                      return (
                        <tr
                          className="shadow-hover-xs-2 bg-hover-white"
                          key={index}
                        >
                          <td className="align-middle pt-4 pb-4 px-5">
                            <div className="row">
                              <div className="col-lg-4">
                                <Link to={`/property/details/${item?.id}`}>
                                  <div className="bg-overlay-gradient-1 bg-hover-overlay-gradient-3 rounded-lg card-img">
                                    <img className="propertylist-img" style={{ height: "200px" }} src={item?.get_property_image} />
                                    <div className="card-img-overlay d-flex flex-column justify-content-between " >
                                      <div><span className="badge badge-orange mr-2">{item?.get_property_property_label}</span></div>
                                    </div>

                                  </div>

                                </Link>
                              </div>

                              <div className="col-lg-8 p-0">
                                <div className="d-flex justify-content-between">
                                  <p
                                    className="mt-3 fs-20 font-weight-bold text-heading mb-0 "
                                    style={{ lineHeight: "1.3" }}
                                  >
                                    {item?.get_property_property_price ? (
                                      <>
                                        <span className="text-black font-weight-500 fs-16">
                                          AED{" "}
                                        </span>
                                        <CurrencyFormat
                                          value={item?.get_property_property_price}
                                          displayType="text"
                                          thousandSeparator={true}
                                          suffix=".00"
                                        />

                                      </>
                                    ) : (
                                      <>
                                       <span className="text-black font-weight-500 fs-16">
                                          AED{" "}
                                        </span>
                                        <CurrencyFormat
                                          value={item?.get_property_lease_price_per_mo}
                                          displayType="text"
                                          thousandSeparator={true}
                                          suffix=".00"
                                        />
                                        <span className="text-gray-light font-weight-500 fs-14">
                                          {" "}
                                          /Mo
                                        </span>
                                      </>
                                    )}
                                  </p>

                                </div>
                                <div
                                  className="list-inline-item text-black font-weight-500 fs-16 d-flex align-items-center mr-5 mb-0"
                                  data-toggle="tooltip"
                                  title="Size"
                                >
                                  {item?.get_property_building_type}
                                  <span className="text-gray-light ml-2 mr-2">
                                    |
                                  </span>
                                  Area: {item?.get_property_property_size}Sq.Ft
                                </div>
                                <h2
                                  className="mt-1"
                                  style={{ lineHeight: "0.5" }}
                                >
                                  <a className="fs-16 text-dark">
                                    {item?.get_property_name}
                                  </a>
                                </h2>

                                <p
                                  className="text-blue  mb-2  "
                                  style={{ lineHeight: "1.7" }}
                                >
                                  <img
                                    src="../images/locations.png"
                                    className="icon icon-bedroom fs-18  mr-1 width-32 mr-2"
                                  />
                                  {item?.get_property_street_address}
                                </p>
                                <CallActionFavButton item={item} />
                                {/* <CallActionButton key={item?.id} item={item} /> */}
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            {moment(item?.create_at).format("DD-MM-YYYY")}
                          </td>
                          <td className="align-middle display-de-none">
                            <span className="badge text-capitalize font-weight-normal fs-12 badge-orange">
                              {item?.get_property_building_type}
                            </span>
                          </td>
                          <td className="align-middle">
                            <a
                              data-bs-toggle="tooltip"
                              title="Delete"
                              className="d-inline-block fs-18 text-muted hover-primary"
                              onClick={() => {
                                setpropertyId(item?.id);
                                handleShow();
                              }}
                            >
                              <i className="fal fa-trash-alt"></i>
                            </a>
                          </td>
                          {/* <td className="aligin-middle pt-10 pb-1 px-10 ">
                              <span className="badge text-capitalize font-weight-normal fs-18 badge-orange">
                                {item?.property_lead_count}
                              </span>
                            </td> */}
                        </tr>
                      );
                    })}
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title> Favourite Property Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure You Want to Delete This  Favourite Property
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleClose();
                  handleDelete();
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </main>
    </>
  );
};

export default FavouriteProperty;
