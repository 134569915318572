
import React, { useEffect, useState } from "react";
import { Map, Marker, Circle, GoogleApiWrapper, InfoWindow, Polyline } from 'google-maps-react';
import { ListGroup, Row, Col, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

const NearbyPlacesMap = (props) => {

  const [nearbyPlacesData, setNearbyPlacesData] = useState([]);

  
  const [coords, setCoords] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState([]);
  const [placesFilter, setPlacesFilter] = useState([]);


  const lat = props?.lat;
  const lng = props?.lng;

  useEffect(() => {
    if (lat || lng) {
      setCoords({ lat, lng })
    }
  }, [lat, lng])
  

  
 
  useEffect(() => {
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

const location = new window.google.maps.LatLng(coords);

const setNearPlaceCount = props.setNearPlaceCount
   
    const NearPlaceList = [

      "Groceries",
      "ShoppingMall",
      "Hospital",
      "Schools",
      "MetroStation"
    ];

    const res = NearPlaceList?.map((item) => {
      const request = {
        location,

        keyword: item,
        radius: "1000", // Specify the radius in meters@
        type: "", // Specify the type of places you want to fetch

      };

      // Send the request to the PlacesService
      placesService?.nearbySearch(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
       
         setPlacesFilter(results);
          setNearbyPlacesData((prevItems) => [
            ...prevItems,
            { ["name"]: item, ["data"]: results, ["count"]: results.length },
          ]);
          setNearPlaceCount((prevItems) => [
            ...prevItems,
            { [item]: results.length },
          ]);
        }
      }
      );
    }

    );
  }, [coords]);
  const handleListItemClick = (place) => {
    // setPlacesFilter(place);
    setSelectedPlace(place);
  };
  // PlacesServiceNear()
  const uniqueData = nearbyPlacesData.filter((item, index, array) => {
    return (
      array.findIndex((obj) => obj.name === item.name) === index
    );
  });

  return (
    <>
      <div className="col-lg-12">
        <div className="row mt-2 ml-1">
          {uniqueData &&
            uniqueData?.map((place, index) => {
         
              return (
                <>
                  <ListGroup as="ol" className="col-lg-4 col-md-3 col-sm-2 mb-2" numbered key={index}>
                    <Link
                      className=""
                      onClick={() => handleListItemClick(place)}
                    // key={index}
                    >
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                      >

                        <div className="fw-bold"> {place.name}</div>

                        <Badge bg="orange" pill>
                          {place.count}{" "}
                        </Badge>
                      </ListGroup.Item>

                      {""}
                    </Link>
                  </ListGroup>
                </>
              );
            })}
        </div>
      </div>

      <div style={{ width: "100%", height: "500px" }}>
        <Map
          google={props.google}
          zoom={selectedPlace ? 14 : 6}
          initialCenter={{ lat, lng }}
          center={{ lat, lng }}
          style={{ height: "500px" }}
        >
          <Marker position={{ lat, lng }} />

          {selectedPlace &&
            selectedPlace.data &&
            selectedPlace.data.map((item) => (
              <Marker
                key={item.place_id}
                icon={{
                  url: `${item.icon}`,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                position={{
                  lat: item?.geometry.location.lat(),
                  lng: item?.geometry.location.lng(),
                }}
               
              >
                {item.name && (
                  <InfoWindow>
                    <div>
                      <h3>{item.name}</h3>
                      {/* Add more information here if needed */}
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))}
        </Map>
      </div>
    </>
  );

};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo',
})(NearbyPlacesMap);