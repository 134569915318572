import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../interceptor/axiosInstance";
import Loader from "../Loader";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;
const Sidebar = () => {
  const [profiledata, setprofiledata] = useState([]);
 
  const [loading, setLoading] = useState('')
  const user_Id = localStorage.getItem("currentUser_id")

  const getprofiledata = () => {
    // setLoading(true);
    if (user_Id) {
      try {
        axiosInstance
          .get(
            `${API_URL}/account/user/profile/${user_Id}/patch/`
          )
          .then((response) => {
            const profileinfo = response.data;

            setprofiledata(profileinfo);
            // setLoading(false);
          })
          .catch((error) => {

            // setLoading(false);
            // Handle the error as needed
          });
      } catch (error) {
        toast.error(' Please Contact Administrator');
        // setLoading(false);
        // Handle the error as needed
      }
    }
  };
  useEffect(() => {
    getprofiledata();
  }, [user_Id]);
  return (
    <>
      {loading ? <Loader /> : null}
      <div className="db-sidebar bg-carbon sb-side">
        <nav className="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0 add-overflow-scroll">
          <div className=" bg-carbon sticky-side sticky-area shadow-xs-1 py-3  ps-fixed">
            <div className="w-100 text-align-center bg-carbon py-2 ">
              <Link
                className="navbar-brand header-user-icon "
                to="/profile"
              >{profiledata?.profile ? (
                <img
                  src={profiledata?.profile}
                  alt="HomeId"
                  className="profile-img border-rd-50 px-2 py-2"
                  style={{ width: "150px" }}
                />
              ) : (

                <img
                  className="profile-img border-rd-50 px-2 py-2"
                  src="../../images/dummy-user.png"
                  alt="HomeID"
                  style={{ width: "150px" }}
                />
              )}
              </Link>
              <div className="">
                <span className="text-muted text-uppercase">welcome</span>
                <h2 className="text-white fs-22 lh-15">
                  {" "}
                  {profiledata?.first_name?.toUpperCase()}{" "}
                  {profiledata?.last_name?.toUpperCase()}
                </h2>
                <span className="text-saffron text-uppercase">
                  {profiledata?.user_role}
                </span>
              </div>
              <div className="ml-auto d-flex align-items-center fd-reverse ">
                <div className="d-flex align-items-center d-xl-none px-3"></div>
                <button
                  className="navbar-toggler border-0 px-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#primaryMenuSidebar"
                  aria-controls="primaryMenuSidebar"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon">
                    <span class="text-white fs-24 font-size-50">
                      <i class="fal fa-bars"></i>
                    </span>
                  </span>
                </button>
              </div>
            </div>
            <div
              className="collapse navbar-collapse bg-white"
              id="primaryMenuSidebar"
            >
              <ul className="list-group list-group-flush w-100">
                <li className="list-group-item pt-6 pb-4 bg-carbon">
                  {/* <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Main</h5> */}
                  <ul className=" text-align-center list-group list-group-no-border rounded-lg">
                    <li
                      className="px-3 px-xl-4 py-2  bg-blue hover-saffron"
                      style={{
                        textAlign: "center",
                        borderRadius: "5px",
                        listStyle: "none",
                      }}
                    >
                      <Link to="/dashboard" className="text-white lh-1 ">
                        <span className="text-white">Dashboard</span>
                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item  header-r-logout">
                      <Link
                        to="/profile/edit"
                        className="text-heading lh-1 sidebar-link"
                      >
                        <span className="text-black">Edit Profile</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="list-group-item pt-6 pb-4 bg-carbon">
                  {/* <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Manage Listings</h5> */}
                  <ul className="list-group list-group-no-border rounded-lg bg-carbon">
                    <li className="list-group-item px-3 px-xl-4 py-2  bg-carbon">
                      <Link
                        to="/profile"
                        className="text-heading lh-1 sidebar-link"
                      >
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20 fs-20">
                          <svg className="icon icon-add-new">
                            <use xlinkHref="#icon-add-new"></use>
                          </svg>
                        </span>
                        <span className="sidebar-item-text text-white text-white">
                          My Profile
                        </span>
                      </Link>
                    </li>
                    {profiledata?.company && (
                      <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                        <Link to="/mycompany" className="text-heading lh-1 sidebar-link">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-add-new">
                              <use xlinkHref="#icon-add-new"></use>
                            </svg>
                          </span>
                          <span className="sidebar-item-text text-white text-white">
                            My Company
                          </span>
                        </Link>
                      </li>
                    )}

                    <li className="list-group-item px-3 px-xl-4 py-2  bg-carbon">
                      <Link
                        to={ "/property/kind/"}
                        // onClick={() => {
                        //   if (!profiledata?.is_paid) {
                        //     toast.error("Please Subscribe Plan First");
                        //   }
                        // }}
                        className="text-heading lh-1 sidebar-link"
                      >
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20 fs-20">
                          <svg className="icon icon-add-new">
                            <use xlinkHref="#icon-add-new"></use>
                          </svg>
                        </span>
                        <span className="sidebar-item-text text-white text-white">
                          Add Property
                        </span>
                      </Link>
                    </li>
                    {/* <li className="list-group-item px-3 px-xl-4 py-2  bg-carbon">
                      <Link
                        to="stats"
                        className="text-heading lh-1 sidebar-link"
                      >
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20 fs-20">
                          <svg className="icon icon-add-new">
                            <use xlinkHref="#icon-add-new"></use>
                          </svg>
                        </span>
                        <span className="sidebar-item-text text-white text-white">
                          My Statistics
                        </span>
                      </Link>
                    </li> */}
                    {/* <li className="list-group-item px-3 px-xl-4 py-2  bg-carbon">
                      <Link
                        to="save-search"
                        className="text-heading lh-1 sidebar-link"
                      >
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20 fs-20">
                          <svg className="icon icon-add-new">
                            <use xlinkHref="#icon-add-new"></use>
                          </svg>
                        </span>
                        <span className="sidebar-item-text text-white text-white">
                          My Search
                        </span>
                      </Link>
                    </li> */}

                    <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon ">
                      <Link
                        to="/property"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center"
                      >
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-properties">
                            <use xlinkHref="#icon-my-properties"></use>
                          </svg>
                        </span>
                        <span className="sidebar-item-text text-white">
                          My Properties
                        </span>
                      </Link>
                    </li>
                    {/* <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon ">
                      <Link
                        to="/mycontact"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center"
                      >
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-properties">
                            <use xlinkHref="#icon-my-properties"></use>
                          </svg>
                        </span>
                        <span className="sidebar-item-text text-white">
                          My Contacts
                        </span>
                      </Link>
                    </li> */}
                    {/* <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                      <Link
                        to="/leads"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center"
                      >
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-heart">
                            <use xlinkHref="#icon-heart"></use>
                          </svg>
                        </span>
                        <span className="sidebar-item-text text-white">
                          My Leads
                        </span>
                      </Link>
                    </li> */}
                    <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                      <Link to="/team">
                        <div className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review">
                              <use xlinkHref="#icon-review"></use>
                            </svg>
                          </span>
                          <span className="sidebar-item-text text-white">
                            My Team
                          </span>
                          {/* <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                        </div>
                      </Link>
                    </li>

                    {/* <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon ">
                      <Link
                        to="reviews"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center"
                      >
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-review">
                            <use xlinkHref="#icon-review"></use>
                          </svg>
                        </span>
                        <span className="sidebar-item-text text-white">
                          My Reviews
                        </span>
                      </Link>
                    </li> */}

                    {/* <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                      <Link
                        to="property/favourite"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center"
                      >
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-review">
                            <use xlinkHref="#icon-review"></use>
                          </svg>
                        </span>
                        <span className="sidebar-item-text text-white">
                          My Favourites
                        </span>
                      </Link>
                    </li> */}

                    {/* <li className="list-group-item px-3 px-xl-4 py-2  text-white bg-carbon">
                      <Link
                        to="/portfolio"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center"
                      >
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-review">
                            <use xlinkHref="#icon-review"></use>
                          </svg>
                        </span>
                        <span className="sidebar-item-text text-white">
                          My Portfolio
                        </span>
                      </Link>
                    </li> */}

                    {/* <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                      <Link
                        to="/archive"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center"
                      >
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-heart">
                            <use xlinkHref="#icon-heart"></use>
                          </svg>
                        </span>
                        <span className="sidebar-item-text text-white">
                          My Archive
                        </span>
                      </Link>
                    </li> */}

                  </ul>
                </li>
                <li className="list-group-item pt-6 pb-4 bg-carbon">
                  <h5
                    className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3"
                    style={{ marginLeft: "28px" }}
                  >
                    Manage Account
                  </h5>
                  <ul className="list-group list-group-no-border rounded-lg">
                    {/* <div className="collapse" id="invoice_collapse">
                      <div className="card card-body border-0 bg-transparent py-0 pl-6">
                        <ul className="list-group list-group-flush list-group-no-border bg-carbon">
                          <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                            <Link
                              to="/listing-invoice"
                              className="text-heading lh-1 sidebar-link"
                            >
                              Listing Invoice
                            </Link>
                          </li>
                          <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                            <Link
                              to="/add-new-invoice"
                              className="text-heading lh-1 sidebar-link"
                            >
                              Add New Invoice
                            </Link>
                          </li>
                          <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                            <Link
                              to="/edit-invoice"
                              className="text-heading lh-1 sidebar-link"
                              href="dashboard-edit-invoice.html"
                            >
                              Edit Invoice
                            </Link>
                          </li>
                          <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                            <Link
                              to="/preview-invoice"
                              className="text-heading lh-1 sidebar-link"
                              href="dashboard-preview-invoice.html"
                            >
                              Preview Invoice
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div> */}
{/* 
                    <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                      <Link
                        to="/invoice"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center"
                      >
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-package">
                            <use xlinkHref="#icon-my-package"></use>
                          </svg>
                        </span>
                        <span className="sidebar-item-text text-white">
                          My Invoices
                        </span>
                      </Link>
                    </li> */}


                    <li className="list-group-item px-3 px-xl-4 py-2 bg-carbon">
                      <Link
                        to="/profile/change-password"
                        className="text-heading lh-1 sidebar-link"
                      >
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-profile">
                            <use xlinkHref="#icon-my-profile"></use>
                          </svg>
                        </span>
                        <span className="sidebar-item-text text-white">
                          Change Password
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
