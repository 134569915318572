import React, { useEffect, useState } from "react";
import BackImage from "../images/about-shape.png";
import teamimage from "../images/agent-1.jpg";
import { Button, Modal } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import Slider from "react-rangeslider";
import Avatar from "react-avatar";
import { BsInstagram, BsTwitter, BsFacebook } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import Loader from "../Loader";
import CurrencyFormat from "react-currency-format";
import axios from "axios";
import ReactWhatsapp from "react-whatsapp";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import MyModal from "../landingPage/MyModal";
import CallActionButton from "../callActionButton/CallActionButton";
import FavActionButton from "../favActionButton/FavActionButton";
import axiosInstance from "../interceptor/axiosInstance";
import moment from "moment";
import PhoneInput from "react-phone-number-input";
const API_URL = process.env.REACT_APP_API_URL;

const ProfileDetails = () => {
  const USER_ID = useParams();
  moment.locale("en");
  const is_auth = localStorage.getItem("is_authenticated");
  const user_id = localStorage.getItem("currentUser_id");
  const { register, handleSubmit, reset } = useForm();

  const [allProperties, setAllProperties] = useState([]);
  const [leadInput, setLeadInput] = useState([]);

  const [loading, setLoading] = useState("");
  const [profiledata, setprofiledata] = useState([]);

  const [saleProperty, setSaleProperty] = useState([]);
  const [rentProperty, setRentProperty] = useState([]);
  const [newProperty, setNewProperty] = useState([]);
  const [teamdata, setteamdata] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [allBro, setAllBro] = useState([]);
  const [reviewData, setreviewData] = useState([]);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [favproperty, setfavproperty] = useState([]);
  const [reviewDescription, setReviewDescription] = useState();
  const [show, setShow] = useState(false);
  const [myphone,setmyphone]=useState();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handlePdfIconClick = (url) => {
    setPdfUrl(url);
    setShowPdfModal(true);
  };
  const handleClosePdfModal = () => {
    setShowPdfModal(false);
    setPdfUrl(""); // Clear the PDF URL when the modal is closed
  };

  const handleVideoClick = (url) => {
    setVideoUrl(url);
    setShowVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
    setVideoUrl(""); // Clear the video URL when the modal is closed
  };

  const getprofiledata = () => {
    setLoading(true);
    const locationParams = `/account/user/profile/${USER_ID?.id}/patch/`;

    axios
      .get(`${API_URL}${locationParams}`)

      .then((response) => {
        const profileinfo = response.data;

        setprofiledata(profileinfo);
        setLoading(false);
      });
  };
  /* *********Api TO Get Favourite property *********/
  const getfavourite = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/properties/user/get/favourite/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setfavproperty(responseData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
      setLoading(false);
    }
  };

  /* ****************api for property list ************* */

  const getAllProperties = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/properties/advance/filter/?user=${USER_ID.id}`
      );
      if ((response.status = 200)) {
        const responseData = response.data;

        setAllProperties(responseData);
        setLoading(false);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  /* ************************get property list for sale*************** */
  useEffect(() => {
    const SaleProperty = allProperties.filter(
      (item) => item.property_label_name === "Sale"
    );
    if (SaleProperty) {
      setSaleProperty(SaleProperty);
    }
    const RentProperty = allProperties.filter(
      (item) => item.property_label_name === "Rent"
    );
    if (RentProperty) {
      setRentProperty(RentProperty);
    }
    const NewProperty = allProperties.filter(
      (item) => item.is_property === true
    );
    if (NewProperty) {
      setNewProperty(NewProperty);
    }
  }, [allProperties, loading]);

  /* ********************************get team list***************** */

  const getteamdata = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/account/get/filter/user/?root_user=${USER_ID?.id}`)
      .then((response) => {
        if (response.data) {
          const teaminfo = response.data;
          setteamdata(teaminfo);
        } else {
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          //  toast.error(error.response?.data?.detail)
          // navigate('/login')
        } else {
        }
        setLoading(false);
      });
  };

  /* ********************************Get portfolio Images ***********   */

  const getAllImages = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/account/user/image/filter/?user=${USER_ID.id}`
      );
      if ((response.status = 200)) {
        const responseData = response.data;

        setAllImages(responseData);
        setLoading(false);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  /* ********************************Get portfolio Videos ***********   */

  const getAllVideos = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/account/user/video/filter/?user=${USER_ID.id}`
      );
      if ((response.status = 200)) {
        const responseData = response.data;

        setAllVideos(responseData);
        setLoading(false);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  /* ********************************Get portfolio Videos ***********   */

  const getAllbrochure = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/account/user/boucher/filter/?user=${USER_ID.id}`
      );
      if ((response.status = 200)) {
        const responseData = response.data;

        setAllBro(responseData);
        setLoading(false);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  /* ******************* Get Review Data ****************** */
  const getReviewData = async () => {
    try {
      const reviewresponse = await axios.get(
        `${API_URL}/account/review/get/${USER_ID.id}/`
      );
      if ((reviewresponse.status = 200)) {
        setreviewData(reviewresponse.data);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  /* ****************lead handler ************* */
  const handlephone = (e) => {
    setmyphone(e);
  };
  const handleLead = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLeadInput({
      ...leadInput,
      [name]: value,
      user: profiledata?.id,
      mobile:myphone,
    });
  };

  const onSubmit = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/account/contact/create/`,
        leadInput
      );
      const myresponse = response.data;

      toast.success("Lead Send successfully");
      reset();
      setmyphone();
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  /********Review Handler */
  const [reviewNumber, setReviewNumber] = useState(0);
  const [rateInput, setRateInput] = useState([]);

  const ratingChanged = (newRating) => {
    setReviewNumber(newRating);
  };

  const handleRating = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setRateInput({
      ...rateInput,
      description: reviewDescription,
      review: reviewNumber,
      user: USER_ID.id,
      review_user: user_id,
    });
  };
  useEffect(() => {
    handleRating();
  }, [reviewDescription, reviewNumber]);

  const submitrating = async () => {
    if (localStorage.getItem("token")) {
      if (reviewNumber !== 0) {
        try {
          const response = await axiosInstance.post(
            `${API_URL}/account/review/create/`,
            rateInput
          );
          const myresponse = response.data;

          toast.success("Rating sent successfully");
          setReviewDescription("");
          setReviewNumber("");
          getReviewData();
        } catch (error) {
          toast.error("Review Allready Submitted");
        }
      } else {
        toast.error("Please Rate First");
      }
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    getprofiledata();
    getAllProperties();
    getteamdata();
    getAllImages();
    getAllVideos();
    getAllbrochure();
    getReviewData();
  }, [USER_ID.id]);

  useEffect(() => {
    window?.scrollTo(0, 0);
    if (is_auth === "false") {
      getfavourite();
    }
  }, [is_auth]);
  return (
    <>
      {loading ? <Loader /> : null}

      <Header />

      <div className="main-content mt-100">
        <main id="content ">
          <div
            className="bg-gray-01"
            style={{ backgroundImage: `url(${BackImage})` }}
          >
            <section className="pb-8">
              <div className="container">
                <div className="row h-100 ">
                  <div className="col-lg-8 mt-20">
                    <div className="card border-0 px-6 pt-6 pb-10 add-pb-full">
                      <div className="row h-100">
                        <div className="col-sm-6 mb-6 mb-sm-0 py-5">
                          {profiledata?.profile ? (
                            <img
                              src={profiledata.profile}
                              alt=""
                              className="card-img set-height-image-dprofilep"
                            />
                          ) : (
                            <img
                              className="card-img set-height-image-dprofilep"
                              src="../../images/pontis_icon.jpg"
                              alt="user-profile"
                            />
                          )}
                        </div>
                        <div className="col-sm-6 py-5">
                          <div className="card-body p-0">
                            <div className="row d-flex">
                              <div className="col-lg-9 p-0">
                                <h2 className="card-title fs-24 lh-15 mb-1 text-dark">
                                  {profiledata?.first_name?.toUpperCase()}{" "}
                                  {profiledata?.last_name?.toUpperCase()}
                                </h2>
                              </div>
                            </div>

                            {/* {profiledata?.reviewlength ? (
                              <>
                                <ul className="list-inline mb-5">
                                  <li className="list-inline-item fs-13 text-heading font-weight-500">
                                    4
                                  </li>

                                  <li className="list-inline-item fs-13 text-heading font-weight-500 mr-1">
                                    <ul className="list-inline mb-0">
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                    </ul>
                                  </li>
                                  <li className="list-inline-item fs-13 text-gray-light">
                                    9
                                  </li>
                                </ul>
                              </>
                            ) : null} */}

                            <ul className="list-group list-group-no-border">
                              {profiledata?.company && (
                                <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                  <span className="col-sm-5 px-1 fw-600 fs-14 text-heading margin-bottom-auto">
                                    Company Name:
                                  </span>
                                  <span className="col-sm-7 px-1 text-heading">
                                    <Link
                                      to={`/company/details/${profiledata?.company}`}
                                      className="text-blue text-uppercase "
                                    >
                                      {profiledata?.company_name}
                                    </Link>
                                  </span>

                                  {/* <span className="col-sm-5 px-1 fw-600 fs-14 text-heading margin-bottom-auto">
                                  Company
                                </span>
                                <span className="col-sm-7 px-1 text-heading">
                                  <Link
                                    to=""
                                    className="text-blue text-uppercase hover-white-text"
                                  >
                                    {profiledata?.company}
                                  </Link>
                                </span> */}
                                </li>
                              )}
                              {profiledata?.user_role ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      Designation:
                                    </span>
                                    <Link className="col-sm-7 px-1 text-heading">
                                      {profiledata?.user_role}
                                      {/* <CurrencyFormat displayType={'text'} value={profileData?.officeNo} format="+61 (###) ###-####" mask="_" /> */}
                                    </Link>
                                  </li>
                                </>
                              ) : null}
                              {profiledata?.phone ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      Office Number:
                                    </span>
                                    <Link className="col-sm-7 px-1 text-heading">
                                      <CurrencyFormat
                                        displayType={"text"}
                                        value={profiledata?.phone}
                                        format=" +###-##-#####"
                                        mask="_"
                                      />
                                    </Link>
                                  </li>
                                </>
                              ) : null}
                              {profiledata?.mobile ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      Mobile Number:
                                    </span>
                                    <Link className="col-sm-7 px-1 text-heading">
                                      <CurrencyFormat
                                        displayType={"text"}
                                        value={profiledata?.mobile}
                                        format=" +###-##-#####"
                                        mask="_"
                                      />
                                    </Link>
                                  </li>
                                </>
                              ) : null}
                              {profiledata?.whatsapp ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      Whatsapp Number:
                                    </span>
                                    <Link className="col-sm-7 px-1 text-heading">
                                      <CurrencyFormat
                                        displayType={"text"}
                                        value={profiledata?.whatsapp}
                                        format=" +###-##-#####"
                                        mask="_"
                                      />
                                    </Link>
                                  </li>
                                </>
                              ) : null}
                              {profiledata?.website_link ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      Web Link:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading">
                                      <Link
                                        to={profiledata?.website_link}
                                        className="text-blue hover-saffron-text create-button-website"
                                        target="_blank"
                                      >
                                        Click Here
                                      </Link>
                                    </span>
                                  </li>{" "}
                                </>
                              ) : null}
                              {profiledata?.street_address ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading margin-bottom-auto">
                                      Address:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading line-height-1-7">
                                      {profiledata?.street_address}
                                    </span>
                                  </li>
                                </>
                              ) : null}

                              {profiledata?.social ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row mb-0 mt-3  p-0 ">
                                    <ul className="col-sm-10 list-inline text-gray-lighter m-0 px-1 text-align-end">
                                      <li className="list-inline-item m-0">
                                        <Link
                                          className="w-32px h-32 rounded hover-saffron bg-blue  text-white  d-flex align-items-center justify-content-center border "
                                          to=""
                                        >
                                          <BsTwitter />
                                        </Link>
                                      </li>
                                      <li className="list-inline-item mr-0 ml-2">
                                        <Link
                                          className="w-32px h-32 rounded hover-saffron bg-blue  text-white  d-flex align-items-center justify-content-center border "
                                          to=""
                                        >
                                          <BsFacebook />
                                        </Link>
                                      </li>
                                      <li className="list-inline-item mr-0 ml-2">
                                        <Link
                                          className="w-32px h-32 rounded hover-saffron bg-blue  text-white  d-flex align-items-center justify-content-center border "
                                          to=""
                                        >
                                          <BsInstagram />
                                        </Link>
                                      </li>
                                    </ul>
                                  </li>{" "}
                                </>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mt-20">
                    <div className="card border-0">
                      <div
                        className="card-body p-6"
                        style={{ display: "grid" }}
                      >
                        <div className=" d-block text-center mb-3 ">
                          <Link className="py-3 bg-blue text-white px-3">
                            I am Interested
                          </Link>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="form-group mb-2 position-add-validate-symbol">
                            <input
                              type="text"
                              className="form-control form-control-lg border-0"
                              placeholder="Your Name"
                              name="name"
                              {...register("name", { required: true })}
                              onChange={handleLead}
                            />
                            <span className="asterisk_input"></span>
                          </div>
                          <div className="form-group mb-2 position-add-validate-symbol">
                            <input
                              type="email"
                              className="form-control form-control-lg border-0"
                              placeholder="Your Email"
                              name="email"
                              {...register("email", { required: true })}
                              onChange={handleLead}
                            />
                            <span className="asterisk_input"></span>
                          </div>
                          <div className="form-group mb-2">
                            {/* <input
                              type="text"
                              className="form-control form-control-lg border-0"
                              placeholder="Your Mobile Number"
                              name="mobile"
                              maxLength={10}
                              {...register("mobile")}
                              onChange={handleLead}
                            /> */}
                            <PhoneInput
                              className="form-control form-control-lg border-1 mb-2 d-flex"
                              defaultCountry="AE"
                              value={myphone}
                              onChange={handlephone}
                            />
                          </div>
                          <div className="form-group mb-4">
                            <textarea
                              className="form-control form-control-lg border-0"
                              rows="3"
                              placeholder="Message"
                              name="message"
                              {...register("msg")}
                              onChange={handleLead}
                            ></textarea>
                          </div>
                          <button
                            type="submit"
                            className="btn bg-blue text-white hover-none btn-block"
                          >
                            Send Message
                          </button>
                        </form>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              {profiledata?.mobile}
                            </Tooltip>
                          }
                        >
                          <Link
                            to={`tel:${profiledata?.mobile}`}
                            className="text-white bg-saffron text-center py-2 mt-1 round-5"

                            // onClick={() => handleCallAction({ ref: item?.prop_id, phone: item?.get_user_mobile, id: item?.id })}
                          >
                            <i className="bi bi-telephone"></i>
                          </Link>
                        </OverlayTrigger>
                        {profiledata?.whatsapp ? (
                          <ReactWhatsapp
                            className="btn btn-success mt-1 py-2`"
                            number={profiledata?.whatsapp}
                            // onClick={() => handleWhatAppAction({ ref: item?.prop_id, phone: item?.get_user_whatsapp, id: item?.id })}
                            message="Hi"
                          >
                            <i class="bi bi-whatsapp"></i>
                          </ReactWhatsapp>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="pb-11">
              <div className="container">
                <div className="collapse-tabs mb-10">
                  <ul
                    className="nav nav-tabs text-uppercase d-md-inline-flex agent-details-tabs"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <Link
                        to="#overview"
                        className=" text-blue nav-link active  fs-13 padding-r-5"
                        data-bs-toggle="tab"
                        role="tab"
                      >
                        Overview
                      </Link>
                    </li>
                    <>
                      <li className="nav-item ml-0">
                        <Link
                          to="#listing"
                          className="nav-link text-blue fs-13 padding-r-5"
                          data-bs-toggle="tab"
                          role="tab"
                        >
                          Listings
                        </Link>
                      </li>
                      <li className="nav-item ml-0">
                        <Link
                          to="#portfolio"
                          className="nav-link text-blue fs-13 padding-r-5"
                          data-bs-toggle="tab"
                          role="tab"
                        >
                          Portfolio
                        </Link>
                      </li>

                      {/* <li className="nav-item ml-0">
                        <Link
                          to="#agents"
                          className="nav-link text-blue fs-13 padding-r-5"
                          data-bs-toggle="tab"
                          role="tab"
                        >
                          Team
                        </Link>
                      </li> */}
                    </>

                    <li className="nav-item ml-0">
                      <Link
                        to="#reviews"
                        className="nav-link text-blue fs-13 padding-r-5"
                        data-bs-toggle="tab"
                        role="tab"
                      >
                        Reviews
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content shadow-none py-7 px-6 bg-white">
                    <div
                      className="tab-pane tab-pane-parent fade show active"
                      id="overview"
                      role="tabpanel"
                    >
                      <div className="card border-0 bg-transparent">
                        <div className="card-body p-0">
                          <h2 className="card-title  lh-15 mb-1 text-dark">
                            {profiledata?.first_name?.toUpperCase()}{" "}
                            {profiledata?.last_name?.toUpperCase()}
                          </h2>
                          <p className="card-text lh-214 mb-3 fs-18">
                            {profiledata?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane tab-pane-parent fade"
                      id="listing"
                      role="tabpanel"
                    >
                      <div className="card border-0 bg-transparent">
                        <div className="card-body p-0">
                          <h2 className="card-title fs-22 lh-15 mb-3 text-dark">
                            Our Listing
                          </h2>
                          <ul
                            className="nav nav-pills text-capitalize nav-pills-01 mx-n5"
                            role="tablist"
                          >
                            <li className="nav-item px-3 px-sm-6 py-1 padding-r-4">
                              <Link
                                to="#all"
                                className="nav-link active shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                                data-bs-toggle="pill"
                                role="tab"
                              >
                                All ({allProperties?.length})
                              </Link>
                            </li>
                            <li className="nav-item ml-0 px-3 px-sm-6 py-1 padding-r-4">
                              <Link
                                to="#newProject"
                                className="nav-link shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                                data-bs-toggle="pill"
                                role="tab"
                              >
                                For New Project ({newProperty?.length})
                              </Link>
                            </li>
                            <li className="nav-item ml-0 px-3 px-sm-6 py-1 padding-r-4">
                              <Link
                                to="#sale"
                                className="nav-link shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                                data-bs-toggle="pill"
                                role="tab"
                              >
                                For Sale ({saleProperty?.length})
                              </Link>
                            </li>
                            <li className="nav-item ml-0 px-3 px-sm-6 py-1 padding-r-4">
                              <Link
                                to="#rent"
                                className="nav-link shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                                data-bs-toggle="pill"
                                role="tab"
                              >
                                For Rent ({rentProperty?.length})
                              </Link>
                            </li>
                          </ul>
                          <div className="tab-content shadow-none pt-5 pb-0 px-0 bg-white">
                            <div
                              className="tab-pane nested-tab-pane fade show active"
                              id="all"
                              role="tabpanel"
                            >
                              {/*  <div className="row"> */}
                              {allProperties.map((item) => {
                                return (

                                    <div className="col-lg-12 mb-7">
                                      <div
                                        className="border-1 round-5 mb-2"
                                        data-animate="fadeInUp"
                                      >
                                        <div>
                                          <div className="row hover-change-image">
                                            <div className="col-lg-4 col-md-6">
                                              <Link
                                                to={`/property/details/${item?.id}`}
                                              >
                                                <div className="bg-overlay-gradient-1 bg-hover-overlay-gradient-3 rounded-lg card-img">
                                                  <img
                                                    className="propertylist-img"
                                                    style={{ height: "200px" }}
                                                    src={
                                                      item?.upload_featured_image
                                                    }
                                                  />
                                                  <div className="card-img-overlay d-flex flex-column justify-content-between ">
                                                    <div>
                                                      <span className="badge badge-orange mr-2">
                                                        {
                                                          item?.property_label_name
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Link>
                                            </div>

                                            <div className="col-lg-8 col-md-6 px-5">
                                              <div className="d-flex justify-content-between">
                                                <p
                                                  className="mt-3 fs-20 font-weight-bold text-heading mb-0 "
                                                  style={{ lineHeight: "1.3" }}
                                                >
                                                  {item?.property_price ? (
                                                    <>
                                                      <span className="text-black font-weight-500 fs-16">
                                                        AED{" "}
                                                      </span>
                                                      <CurrencyFormat
                                                        value={
                                                          item?.property_price
                                                        }
                                                        displayType="text"
                                                        thousandSeparator={true}
                                                        suffix=".00"
                                                      />
                                                      {/* <span className="text-gray-light font-weight-500 fs-14"> /Mo</span> */}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <CurrencyFormat
                                                        value={
                                                          item?.lease_price_per_mo
                                                        }
                                                        displayType="text"
                                                        thousandSeparator={true}
                                                        suffix=".00"
                                                      />
                                                      <span className="text-gray-light font-weight-500 fs-14">
                                                        {" "}
                                                        /Mo
                                                      </span>
                                                    </>
                                                  )}
                                                </p>
                                                <div className="d-flex">
                                                  {item?.is_property ===
                                                  true ? (
                                                    <div>
                                                      <span className="badge badge-orange mt-2 mr-2">
                                                        New
                                                      </span>
                                                    </div>
                                                  ) : null}
                                                  <div className="mt-2 mr-2">
                                                    <FavActionButton
                                                      setShow={setShow}
                                                      itemId={item?.id}
                                                      favproperty={favproperty}
                                                      setfavproperty={
                                                        setfavproperty
                                                      }
                                                      setLoading={setLoading}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="list-inline-item text-black font-weight-500 fs-15 d-flex align-items-center mr-5 mb-0"
                                                data-toggle="tooltip"
                                                title="Size"
                                              >
                                                {item?.building_type_name}
                                                <span className="text-gray-light ml-2 mr-2">
                                                  |
                                                </span>
                                                Area: {item?.property_size}Sq.Ft
                                                {item?.building_type_name ===
                                                "Residential" ? (
                                                  <>
                                                    {item?.bedroom !== null &&
                                                    item?.bathroom !== null ? (
                                                      <>
                                                        <span className="text-gray-light ml-2 mr-2">
                                                          |
                                                        </span>
                                                        {item?.bedroom}{" "}
                                                        <img
                                                          src="../../images/bedd.png"
                                                          className="icon icon-bedroom fs-18  mr-1 width-32 ml-1 mr-2"
                                                        />
                                                        {item?.bathroom}{" "}
                                                        <img
                                                          src="../../images/bathtubb.png"
                                                          className="icon icon-bedroom fs-18 mr-1 mr-1 width-32"
                                                        />
                                                      </>
                                                    ) : null}
                                                  </>
                                                ) : null}
                                              </div>
                                              <h2
                                                className="mt-1"
                                                style={{ lineHeight: "0.5" }}
                                              >
                                                <Link className="fs-16 text-dark">
                                                  {item?.property_name}
                                                </Link>
                                              </h2>

                                              <p
                                                className="text-blue  mb-2 "
                                                style={{ lineHeight: "1.7" }}
                                              >
                                                <img
                                                  src="../../images/locations.png"
                                                  className="icon icon-bedroom fs-18  mr-1 width-32 "
                                                />
                                                {item?.street_address}
                                              </p>

                                              <CallActionButton
                                                key={item?.id}
                                                item={item}
                                              />
                                            </div>

                                            {/* <div className="card-footer bg-transparent d-flex justify-content-between align-items-center py-3 border">
                                                    <div className="mr-auto">
                                                        <p className="fs-17 font-weight-bold text-heading mb-0 px-1"><CurrencyFormat value="121212" displayType='text' thousandSeparator={true} suffix=".00AED" />
                                                            <span
                                                                className="text-gray-light font-weight-500 fs-14"> /Mo</span>
                                                        </p>
                                                    </div>
                                                    <FavCard proId={item?.id} IndexValue={index} favData={favProp} />
                                                </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                );
                              })}

                              {/* </div> */}
                            </div>
                            <div
                              className="tab-pane nested-tab-pane fade"
                              id="newProject"
                              role="tabpanel"
                            >
                              {newProperty.map((item) => {
                                return (
                                  <>
                                    <div className="col-lg-12 mb-7">
                                      <div
                                        className="border-1 round-5 mb-2"
                                        data-animate="fadeInUp"
                                      >
                                        <div>
                                          <div className="row hover-change-image">
                                            <div className="col-lg-4 col-md-6">
                                              <Link
                                                to={`/property/details/${item?.id}`}
                                              >
                                                <div className="bg-overlay-gradient-1 bg-hover-overlay-gradient-3 rounded-lg card-img">
                                                  <img
                                                    className="propertylist-img"
                                                    style={{ height: "200px" }}
                                                    src={
                                                      item?.upload_featured_image
                                                    }
                                                  />
                                                  <div className="card-img-overlay d-flex flex-column justify-content-between ">
                                                    <div>
                                                      <span className="badge badge-orange mr-2">
                                                        {
                                                          item?.property_label_name
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Link>
                                            </div>

                                            <div className="col-lg-8 col-md-6 px-5">
                                              <div className="d-flex justify-content-between">
                                                <p
                                                  className="mt-3 fs-20 font-weight-bold text-heading mb-0 "
                                                  style={{ lineHeight: "1.3" }}
                                                >
                                                  {item?.property_price ? (
                                                    <>
                                                      <span className="text-black font-weight-500 fs-16">
                                                        AED{" "}
                                                      </span>
                                                      <CurrencyFormat
                                                        value={
                                                          item?.property_price
                                                        }
                                                        displayType="text"
                                                        thousandSeparator={true}
                                                        suffix=".00"
                                                      />
                                                      {/* <span className="text-gray-light font-weight-500 fs-14"> /Mo</span> */}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <CurrencyFormat
                                                        value={
                                                          item?.lease_price_per_mo
                                                        }
                                                        displayType="text"
                                                        thousandSeparator={true}
                                                        suffix=".00"
                                                      />
                                                      <span className="text-gray-light font-weight-500 fs-14">
                                                        {" "}
                                                        /Mo
                                                      </span>
                                                    </>
                                                  )}
                                                </p>
                                                <div className="d-flex">
                                                  {item?.is_property ===
                                                  true ? (
                                                    <div>
                                                      <span className="badge badge-orange mt-2 mr-2">
                                                        New
                                                      </span>
                                                    </div>
                                                  ) : null}
                                                  <div className="mt-2 mr-2">
                                                    <FavActionButton
                                                      setShow={setShow}
                                                      itemId={item?.id}
                                                      favproperty={favproperty}
                                                      setfavproperty={
                                                        setfavproperty
                                                      }
                                                      setLoading={setLoading}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="list-inline-item text-black font-weight-500 fs-15 d-flex align-items-center mr-5 mb-0"
                                                data-toggle="tooltip"
                                                title="Size"
                                              >
                                                {item?.building_type_name}
                                                <span className="text-gray-light ml-2 mr-2">
                                                  |
                                                </span>
                                                Area: {item?.property_size}Sq.Ft
                                                {item?.building_type_name ===
                                                "Residential" ? (
                                                  <>
                                                    {item?.bedroom !== null &&
                                                    item?.bathroom !== null ? (
                                                      <>
                                                        <span className="text-gray-light ml-2 mr-2">
                                                          |
                                                        </span>
                                                        {item?.bedroom}{" "}
                                                        <img
                                                          src="../../images/bedd.png"
                                                          className="icon icon-bedroom fs-18  mr-1 width-32 ml-1 mr-2"
                                                        />
                                                        {item?.bathroom}{" "}
                                                        <img
                                                          src="../../images/bathtubb.png"
                                                          className="icon icon-bedroom fs-18 mr-1 mr-1 width-32"
                                                        />
                                                      </>
                                                    ) : null}
                                                  </>
                                                ) : null}
                                              </div>
                                              <h2
                                                className="mt-1"
                                                style={{ lineHeight: "0.5" }}
                                              >
                                                <Link className="fs-16 text-dark">
                                                  {item?.property_name}
                                                </Link>
                                              </h2>

                                              <p
                                                className="text-blue  mb-2 "
                                                style={{ lineHeight: "1.7" }}
                                              >
                                                <img
                                                  src="../../images/locations.png"
                                                  className="icon icon-bedroom fs-18  mr-1 width-32 "
                                                />
                                                {item?.street_address}
                                              </p>

                                              <CallActionButton
                                                key={item?.id}
                                                item={item}
                                              />
                                            </div>

                                            {/* <div className="card-footer bg-transparent d-flex justify-content-between align-items-center py-3 border">
                                                    <div className="mr-auto">
                                                        <p className="fs-17 font-weight-bold text-heading mb-0 px-1"><CurrencyFormat value="121212" displayType='text' thousandSeparator={true} suffix=".00AED" />
                                                            <span
                                                                className="text-gray-light font-weight-500 fs-14"> /Mo</span>
                                                        </p>
                                                    </div>
                                                    <FavCard proId={item?.id} IndexValue={index} favData={favProp} />
                                                </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                            <div
                              className="tab-pane nested-tab-pane fade"
                              id="sale"
                              role="tabpanel"
                            >
                              {saleProperty.map((item) => {
                                return (
                                  <>
                                    <div className="col-lg-12 mb-7">
                                      <div
                                        className="border-1 round-5 mb-2"
                                        data-animate="fadeInUp"
                                      >
                                        <div>
                                          <div className="row hover-change-image">
                                            <div className="col-lg-4 col-md-6">
                                              <Link
                                                to={`/property/details/${item?.id}`}
                                              >
                                                <div className="bg-overlay-gradient-1 bg-hover-overlay-gradient-3 rounded-lg card-img">
                                                  <img
                                                    className="propertylist-img"
                                                    style={{ height: "200px" }}
                                                    src={
                                                      item?.upload_featured_image
                                                    }
                                                  />
                                                  <div className="card-img-overlay d-flex flex-column justify-content-between ">
                                                    <div>
                                                      <span className="badge badge-orange mr-2">
                                                        {
                                                          item?.property_label_name
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Link>
                                            </div>

                                            <div className="col-lg-8 col-md-6 px-5">
                                              <div className="d-flex justify-content-between">
                                                <p
                                                  className="mt-3 fs-20 font-weight-bold text-heading mb-0 "
                                                  style={{ lineHeight: "1.3" }}
                                                >
                                                  {item?.property_price ? (
                                                    <>
                                                      <span className="text-black font-weight-500 fs-16">
                                                        AED{" "}
                                                      </span>
                                                      <CurrencyFormat
                                                        value={
                                                          item?.property_price
                                                        }
                                                        displayType="text"
                                                        thousandSeparator={true}
                                                        suffix=".00"
                                                      />
                                                      {/* <span className="text-gray-light font-weight-500 fs-14"> /Mo</span> */}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <CurrencyFormat
                                                        value={
                                                          item?.lease_price_per_mo
                                                        }
                                                        displayType="text"
                                                        thousandSeparator={true}
                                                        suffix=".00"
                                                      />
                                                      <span className="text-gray-light font-weight-500 fs-14">
                                                        {" "}
                                                        /Mo
                                                      </span>
                                                    </>
                                                  )}
                                                </p>
                                                <div className="d-flex">
                                                  {item?.is_property ===
                                                  true ? (
                                                    <div>
                                                      <span className="badge badge-orange mt-2 mr-2">
                                                        New
                                                      </span>
                                                    </div>
                                                  ) : null}
                                                  <div className="mt-2 mr-2">
                                                    <FavActionButton
                                                      setShow={setShow}
                                                      itemId={item?.id}
                                                      favproperty={favproperty}
                                                      setfavproperty={
                                                        setfavproperty
                                                      }
                                                      setLoading={setLoading}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="list-inline-item text-black font-weight-500 fs-15 d-flex align-items-center mr-5 mb-0"
                                                data-toggle="tooltip"
                                                title="Size"
                                              >
                                                {item?.building_type_name}
                                                <span className="text-gray-light ml-2 mr-2">
                                                  |
                                                </span>
                                                Area: {item?.property_size}Sq.Ft
                                                {item?.building_type_name ===
                                                "Residential" ? (
                                                  <>
                                                    {item?.bedroom !== null &&
                                                    item?.bathroom !== null ? (
                                                      <>
                                                        <span className="text-gray-light ml-2 mr-2">
                                                          |
                                                        </span>
                                                        {item?.bedroom}{" "}
                                                        <img
                                                          src="../../images/bedd.png"
                                                          className="icon icon-bedroom fs-18  mr-1 width-32 ml-1 mr-2"
                                                        />
                                                        {item?.bathroom}{" "}
                                                        <img
                                                          src="../../images/bathtubb.png"
                                                          className="icon icon-bedroom fs-18 mr-1 mr-1 width-32"
                                                        />
                                                      </>
                                                    ) : null}
                                                  </>
                                                ) : null}
                                              </div>
                                              <h2
                                                className="mt-1"
                                                style={{ lineHeight: "0.5" }}
                                              >
                                                <Link className="fs-16 text-dark">
                                                  {item?.property_name}
                                                </Link>
                                              </h2>

                                              <p
                                                className="text-blue  mb-2 "
                                                style={{ lineHeight: "1.7" }}
                                              >
                                                <img
                                                  src="../../images/locations.png"
                                                  className="icon icon-bedroom fs-18  mr-1 width-32 "
                                                />
                                                {item?.street_address}
                                              </p>

                                              <CallActionButton
                                                key={item?.id}
                                                item={item}
                                              />
                                            </div>

                                            {/* <div className="card-footer bg-transparent d-flex justify-content-between align-items-center py-3 border">
                                                    <div className="mr-auto">
                                                        <p className="fs-17 font-weight-bold text-heading mb-0 px-1"><CurrencyFormat value="121212" displayType='text' thousandSeparator={true} suffix=".00AED" />
                                                            <span
                                                                className="text-gray-light font-weight-500 fs-14"> /Mo</span>
                                                        </p>
                                                    </div>
                                                    <FavCard proId={item?.id} IndexValue={index} favData={favProp} />
                                                </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                            <div
                              className="tab-pane nested-tab-pane fade"
                              id="rent"
                              role="tabpanel"
                            >
                              {rentProperty.map((item) => {
                                return (
                                  <>
                                    <div className="col-lg-12 mb-7">
                                      <div
                                        className="border-1 round-5 mb-2"
                                        data-animate="fadeInUp"
                                      >
                                        <div>
                                          <div className="row hover-change-image">
                                            <div className="col-lg-4 col-md-6">
                                              <Link
                                                to={`/property/details/${item?.id}`}
                                              >
                                                <div className="bg-overlay-gradient-1 bg-hover-overlay-gradient-3 rounded-lg card-img">
                                                  <img
                                                    className="propertylist-img"
                                                    style={{ height: "200px" }}
                                                    src={
                                                      item?.upload_featured_image
                                                    }
                                                  />
                                                  <div className="card-img-overlay d-flex flex-column justify-content-between ">
                                                    <div>
                                                      <span className="badge badge-orange mr-2">
                                                        {
                                                          item?.property_label_name
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Link>
                                            </div>

                                            <div className="col-lg-8 col-md-6 px-5">
                                              <div className="d-flex justify-content-between">
                                                <p
                                                  className="mt-3 fs-20 font-weight-bold text-heading mb-0 "
                                                  style={{ lineHeight: "1.3" }}
                                                >
                                                  {item?.property_price ? (
                                                    <>
                                                      <span className="text-black font-weight-500 fs-16">
                                                        AED{" "}
                                                      </span>
                                                      <CurrencyFormat
                                                        value={
                                                          item?.property_price
                                                        }
                                                        displayType="text"
                                                        thousandSeparator={true}
                                                        suffix=".00"
                                                      />
                                                      {/* <span className="text-gray-light font-weight-500 fs-14"> /Mo</span> */}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <CurrencyFormat
                                                        value={
                                                          item?.lease_price_per_mo
                                                        }
                                                        displayType="text"
                                                        thousandSeparator={true}
                                                        suffix=".00"
                                                      />
                                                      <span className="text-gray-light font-weight-500 fs-14">
                                                        {" "}
                                                        /Mo
                                                      </span>
                                                    </>
                                                  )}
                                                </p>
                                                <div className="d-flex">
                                                  {item?.is_property ===
                                                  true ? (
                                                    <div>
                                                      <span className="badge badge-orange mt-2 mr-2">
                                                        New
                                                      </span>
                                                    </div>
                                                  ) : null}
                                                  <div className="mt-2 mr-2">
                                                    <FavActionButton
                                                      setShow={setShow}
                                                      itemId={item?.id}
                                                      favproperty={favproperty}
                                                      setfavproperty={
                                                        setfavproperty
                                                      }
                                                      setLoading={setLoading}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="list-inline-item text-black font-weight-500 fs-15 d-flex align-items-center mr-5 mb-0"
                                                data-toggle="tooltip"
                                                title="Size"
                                              >
                                                {item?.building_type_name}
                                                <span className="text-gray-light ml-2 mr-2">
                                                  |
                                                </span>
                                                Area: {item?.property_size}Sq.Ft
                                                {item?.building_type_name ===
                                                "Residential" ? (
                                                  <>
                                                    {item?.bedroom !== null &&
                                                    item?.bathroom !== null ? (
                                                      <>
                                                        <span className="text-gray-light ml-2 mr-2">
                                                          |
                                                        </span>
                                                        {item?.bedroom}{" "}
                                                        <img
                                                          src="../../images/bedd.png"
                                                          className="icon icon-bedroom fs-18  mr-1 width-32 ml-1 mr-2"
                                                        />
                                                        {item?.bathroom}{" "}
                                                        <img
                                                          src="../../images/bathtubb.png"
                                                          className="icon icon-bedroom fs-18 mr-1 mr-1 width-32"
                                                        />
                                                      </>
                                                    ) : null}
                                                  </>
                                                ) : null}
                                              </div>
                                              <h2
                                                className="mt-1"
                                                style={{ lineHeight: "0.5" }}
                                              >
                                                <Link className="fs-16 text-dark">
                                                  {item?.property_name}
                                                </Link>
                                              </h2>

                                              <p
                                                className="text-blue  mb-2 "
                                                style={{ lineHeight: "1.7" }}
                                              >
                                                <img
                                                  src="../../images/locations.png"
                                                  className="icon icon-bedroom fs-18  mr-1 width-32 "
                                                />
                                                {item?.street_address}
                                              </p>

                                              <CallActionButton
                                                key={item?.id}
                                                item={item}
                                              />
                                            </div>

                                            {/* <div className="card-footer bg-transparent d-flex justify-content-between align-items-center py-3 border">
                                                    <div className="mr-auto">
                                                        <p className="fs-17 font-weight-bold text-heading mb-0 px-1"><CurrencyFormat value="121212" displayType='text' thousandSeparator={true} suffix=".00AED" />
                                                            <span
                                                                className="text-gray-light font-weight-500 fs-14"> /Mo</span>
                                                        </p>
                                                    </div>
                                                    <FavCard proId={item?.id} IndexValue={index} favData={favProp} />
                                                </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane tab-pane-parent fade"
                      id="portfolio"
                      role="tabpanel"
                    >
                      <div className="card border-0 bg-transparent">
                        <div className="card-body p-0">
                          <h2 className="card-title fs-22 lh-15 mb-3 text-dark">
                            Our Gallery
                          </h2>
                          <ul
                            className="nav nav-pills text-capitalize nav-pills-01 mx-n5"
                            role="tablist"
                          >
                            <li className="nav-item px-3 px-sm-6 py-1 padding-r-4">
                              <Link
                                to="#images"
                                className="nav-link active shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                                data-bs-toggle="pill"
                                role="tab"
                              >
                                Images
                              </Link>
                            </li>
                            <li className="nav-item ml-0 px-3 px-sm-6 py-1 padding-r-4">
                              <Link
                                to="#videos"
                                className="nav-link shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                                data-bs-toggle="pill"
                                role="tab"
                              >
                                Videos
                              </Link>
                            </li>
                            <li className="nav-item ml-0 px-3 px-sm-6 py-1 padding-r-4">
                              <Link
                                to="#brochure"
                                className="nav-link shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                                data-bs-toggle="pill"
                                role="tab"
                              >
                                Brochure
                              </Link>
                            </li>
                          </ul>
                          <div className="tab-content shadow-none pt-5 pb-0 px-0 bg-white">
                            <div
                              className="tab-pane nested-tab-pane fade show active"
                              id="images"
                              role="tabpanel"
                            >
                              {/*  <div className="row"> */}
                              {allImages.map((item) =>
                                item.image ? (
                                  <div className="col-lg-12 mb-7">
                                    <Link to="">
                                      <div className=" border-1 px-2 py-2">
                                        <div className="row">
                                          <div className="col-lg-4 hover-change-image bg-hover-overlay  mr-2">
                                            <img
                                              className="rounded-lg"
                                              src={item?.image}
                                              alt="Villa on Hollywood Boulevard"
                                            />
                                          </div>
                                          <div className="col-lg-6">
                                            <div className="pt-3 px-0 pb-1">
                                              <h2 className="fs-20 mb-1">
                                                <Link className="text-dark ">
                                                  {item?.txt}
                                                </Link>
                                              </h2>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                ) : null
                              )}

                              {/* </div> */}
                            </div>
                            <div
                              className="tab-pane nested-tab-pane fade"
                              id="videos"
                              role="tabpanel"
                            >
                              {allVideos.map((item, index) =>
                                item?.video ? (
                                  <div className="col-lg-12 mb-7" key={index}>
                                    <Link to="">
                                      <div className="border-1 px-2 py-2">
                                        <div className="row">
                                          <div
                                            className="col-lg-4 mr-2"
                                            onClick={() =>
                                              handleVideoClick(item?.video)
                                            }
                                          >
                                            <video
                                              src={item?.video}
                                              alt="Explore Old Barcelona"
                                              controls
                                              width="100%"
                                              height="auto"
                                            />
                                          </div>
                                          <div className="col-lg-6">
                                            <div className="pt-3 px-0 pb-1">
                                              <h2 className="fs-20 mb-1">
                                                <Link className="text-dark">
                                                  {item?.txt}
                                                </Link>
                                              </h2>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                ) : null
                              )}
                              <Modal
                                show={showVideoModal}
                                onHide={handleCloseVideoModal}
                                centered
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Video</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {/* Display the video in the modal */}
                                  <video
                                    src={videoUrl}
                                    controls
                                    width="100%"
                                    height="auto"
                                  />
                                </Modal.Body>
                              </Modal>
                            </div>
                            <div
                              className="tab-pane nested-tab-pane fade"
                              id="brochure"
                              role="tabpanel"
                            >
                              {allBro.map((item) =>
                                item?.pdf ? (
                                  <div className="col-lg-12 mb-7">
                                    <Link to="">
                                      <div className=" border-1 px-2 py-2">
                                        <div className="row">
                                          <div
                                            className="col-lg-4   mr-2"
                                            onClick={() =>
                                              handlePdfIconClick(item?.pdf)
                                            }
                                          >
                                            <i className="bi bi-file-earmark-pdf fa-lg"></i>
                                          </div>
                                          <div className="col-lg-6">
                                            <div className="pt-3 px-0 pb-1">
                                              <h2 className="fs-20 mb-1">
                                                <Link className="text-dark ">
                                                  {item?.txt}
                                                </Link>
                                              </h2>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                ) : null
                              )}
                              <Modal
                                show={showPdfModal}
                                onHide={handleClosePdfModal}
                                centered
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>PDF Viewer</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {/* Display the PDF in the modal */}
                                  <iframe
                                    src={pdfUrl}
                                    title="PDF Viewer"
                                    width="100%"
                                    height="500px"
                                  ></iframe>
                                </Modal.Body>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className="tab-pane tab-pane-parent fade"
                      id="agents"
                      role="tabpanel"
                    >
                      <div className="card border-0 bg-transparent">
                        <div className="card-body p-0">
                          <h2 className="card-title fs-22 lh-15 mb-6 text-dark">
                            Our Team
                          </h2>
                          <div className="d-flex flex-wrap listing-agents">
                            {teamdata?.map((item) => {
                              return (
                                <div className="card border-0 px-7 mb-8">
                                  <div className="card-body p-0 text-center">
                                    <Link
                                      to={`/profile/details/${item?.id}`}
                                      className="d-block text-center"
                                    >
                                      {item?.profile ? (
                                        <img
                                          src={item?.profile}
                                          className="mb-2 image-team-user-profile"
                                          alt="Oliver Beddows"
                                        />
                                      ) : (
                                        <Avatar
                                          name={item?.first_name}
                                          round={true}
                                          size="50"
                                          color="#529BE8"
                                        />
                                      )}
                                    </Link>
                                    <Link
                                      to={`/profile/details/${item?.id}`}
                                      className="card-title d-block text-dark fs-16 font-weight-500 lh-2  mb-0"
                                    >
                                      {item?.first_name.toUpperCase()}{" "}
                                      {item?.last_name.toUpperCase()}
                                    </Link>
                                    <p className="card-text">
                                      {item?.user_role}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div
                      className="tab-pane tab-pane-parent fade"
                      id="reviews"
                      role="tabpanel"
                    >
                      <div className="card border-0 bg-transparent">
                        <div className="card-body p-0">
                          <h2 className="fs-22 text-heading lh-15 mb-6">
                            User Rating & Reviews{" "}
                          </h2>
                          {profiledata?.average_review ? (
                            <div className="row mb-6">
                              <div className="col-sm-4 mb-6 mb-sm-0">
                                <h5 className="fs-16 lh-2 text-heading mb-6">
                                  Avarage User Rating
                                </h5>
                                <p className="fs-40 text-heading font-weight-bold mb-6 lh-1">
                                  {profiledata?.average_review.toFixed(1)}
                                  <span className="fs-18 text-gray-light font-weight-normal">
                                    /5
                                  </span>
                                </p>

                                {/* {userRatingCount?.round_off ? ( */}
                                <ul className="list-inline">
                                  <ReactStars
                                    value={profiledata?.average_review}
                                    edit={false}
                                    size={40}
                                    isHalf={true}
                                    a11y={true}
                                  />
                                </ul>
                                {/* ) : (
                                <ReactStars value="0" edit={false} size={40} />
                              )} */}
                              </div>
                            </div>
                          ) : null}

                          <h3 className="fs-16 lh-2 text-heading mb-6">
                            Reviews
                          </h3>
                          {reviewData
                            ? reviewData.map((item) => (
                                <div
                                  className="row border-top mt-3 pt-6"
                                  key={item.id}
                                >
                                  <div className="col-md-3 mb-4 mb-md-0">
                                    <div className="media">
                                      <div className="h-70 mr-2">
                                        <Avatar
                                          name={item.user_review_user}
                                          round={true}
                                          size="50"
                                          color="#529BE8"
                                        />
                                      </div>
                                      <div className="media-body mt-2">
                                        <p className="fs-16 font-weight-500 text-heading mb-0 lh-15 add-mleft-for-avatar">
                                          {item.user_review_user}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-9">
                                    <div className="d-flex mb-1">
                                      <ul className="list-inline mb-2 mb-lg-0">
                                        <li className="list-inline-item fs-13 text-heading font-weight-500 mr-1">
                                          <ReactStars
                                            value={item.review}
                                            count={5}
                                            edit={false}
                                            size={26}
                                          />
                                        </li>
                                      </ul>
                                      <p className="ml-auto mb-0 text-muted">
                                        {moment(item?.create_at)
                                          .format("DD-MM-YY")
                                          .toUpperCase()}
                                      </p>
                                    </div>
                                    <p className="mb-1">{item.description}</p>
                                  </div>
                                </div>
                              ))
                            : null}

                          <h3 className="mt-5 border-top fs-16 lh-2 text-heading mb-2">
                            Write A Review
                          </h3>
                          <div className=" mb-5">
                            <div className="form-group mb-4 d-flex justify-content-start ">
                              <ReactStars
                                count={5}
                                size={36}
                                activeColor="#F6B500"
                                name="review"
                                onChange={ratingChanged}
                              />
                            </div>
                            <div className="form-group mb-6">
                              <textarea
                                className="form-control form-control-lg border-0"
                                placeholder="Your Review"
                                name="description"
                                rows="5"
                                onChange={(e) =>
                                  setReviewDescription(e?.target?.value)
                                }
                                value={reviewDescription}
                                // {...register("description", { required: true })}
                                // onChange={handleRating}
                              ></textarea>
                            </div>
                            <button
                              type="button"
                              className="btn btn-lg bg-blue text-white hover-saffron px-10 mb-2"
                              onClick={submitrating}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      <MyModal show={show} handleClose={handleClose} />
      <Footer />
    </>
  );
};

export default ProfileDetails;
