import React, { useEffect, useState } from "react";
import BackImage from "../../../images/about-shape.png";
import { BsInstagram, BsTwitter, BsFacebook } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import Loader from "../../../Loader";
import CurrencyFormat from "react-currency-format";
import axios from "axios";
import Header from "../../../header/Header";
import Footer from "../../../footer/Footer";
import axiosInstance from "../../../interceptor/axiosInstance";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
const API_URL = process.env.REACT_APP_API_URL;

const Profile = () => {
  const USER_ID = useParams();

  const [allImages, setallImages] = useState([]);
  const [loading, setLoading] = useState("");
  const [profiledata, setprofiledata] = useState([]);

  const [brochure, setbrochure] = useState([]);
  const [videodata, setvideodata] = useState([]);
  const { register, handleSubmit } = useForm();
  const [leadInput, setLeadInput] = useState([]);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("")
  // Add state for PDF modal
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const handlePdfIconClick = (url) => {
    setPdfUrl(url);
    setShowPdfModal(true);
  };

  const handleVideoClick = (url) => {
    setVideoUrl(url);
    setShowVideoModal(true);
  };
  const handleImageClick = (url) => {
    setImageUrl(url);
    setShowImageModal(true);
  };
  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setImageUrl(""); // Clear the video URL when the modal is closed
  };
  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
    setVideoUrl(""); // Clear the video URL when the modal is closed
  };

  const handleClosePdfModal = () => {
    setShowPdfModal(false);
    setPdfUrl(""); // Clear the PDF URL when the modal is closed
  };

  const user_Id = localStorage.getItem("currentUser_id");
  const getprofiledata = () => {
    setLoading(true);
    const locationParams =
      user_Id !== (undefined || null)
        ? `/account/user/profile/${user_Id}/patch/`
        : `/account/user/profile/${USER_ID?.id}/patch/`;

    axios
      .get(`${API_URL}${locationParams}`)

      .then((response) => {
        const profileinfo = response.data;

        setprofiledata(profileinfo);
        setLoading(false);
      });
  };

  /* ****************api for Portfolio image list ************* */

  const getallImages = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/account/user/image/get/`
      );
      if (response.status === 200) {
        const responseData = response.data;

        setallImages(responseData);
        setLoading(false);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  /* ********************************get Video list***************** */

  const getvideodata = () => {
    setLoading(true);
    axiosInstance
      .get(`${API_URL}/account/user/video/get/`)
      .then((response) => {
        if (response.data) {
          const videoinfo = response.data;
          setvideodata(videoinfo);
        } else {
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
        } else {
        }
        setLoading(false);
      });
  };

  /* *************************** get Brochure Data**************** */
  const getbrochuredata = () => {
    setLoading(true);
    axiosInstance
      .get(`${API_URL}/account/user/boucher/get/`)
      .then((response) => {
        if (response.data) {
          const info = response.data;
          setbrochure(info);
        } else {
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
        } else {
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    getprofiledata();
    getallImages();
    getvideodata();
    getbrochuredata();
  }, [user_Id, USER_ID.id]);

  /* ****************lead handler ************* */

  const handleLead = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLeadInput({ ...leadInput, [name]: value, user: profiledata?.id });
  };

  const onSubmit = async () => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/account/contact/create/`,
        leadInput
      );
      const myresponse = response.data;

      toast.success("Lead Send successfully");
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      {USER_ID?.id ? <Header /> : null}

      <div className="main-content">
        <main id="content ">
          <div
            className="bg-gray-01"
            style={{ backgroundImage: `url(${BackImage})` }}
          >
            <section className="pb-8">
              <div className="container">
                <div className="row h-100 ">
                  <div className="col-lg-12 mt-20">
                    <div className="card border-0 px-6 pt-6 pb-10 add-pb-full">
                      <div className="row h-100">
                        <div className="col-sm-4 mb-6 mb-sm-0 py-5">
                          {profiledata?.profile ? (
                            <img
                              src={profiledata.profile}
                              alt=""
                              className="rounded-lg  card-img set-height-image-dprofilep"
                            />
                          ) : (
                            <img
                              className="rounded-lg  card-img set-height-image-dprofilep"
                              src="../../images/pontis_icon.jpg"
                              alt="user-profile"
                            />
                          )}
                        </div>
                        <div className="col-sm-8 py-5">
                          <div className="card-body p-0">
                            <div className="row d-flex">
                              <div className="col-lg-9">
                                <h2 className="card-title fs-26 lh-15 mb-1 text-dark">
                                  {profiledata?.first_name?.toUpperCase()}{" "}
                                  {profiledata?.last_name?.toUpperCase()}
                                </h2>
                              </div>
                              {USER_ID?.id ? (
                                <></>
                              ) : (
                                <>
                                  <div className="col-lg-3">
                                    <Link
                                      to={`/profile/edit/${profiledata?.id}`}
                                      className="btn bg-blue text-white hover-saffron"
                                    >
                                      Edit
                                    </Link>
                                  </div>
                                </>
                              )}
                            </div>

                            <p className="card-text mb-2 text-blue">
                              {profiledata?.user_role}
                            </p>
                            {profiledata?.reviewlength ? (
                              <>
                                <ul className="list-inline mb-5">
                                  <li className="list-inline-item fs-13 text-heading font-weight-500">
                                    4
                                  </li>

                                  <li className="list-inline-item fs-13 text-heading font-weight-500 mr-1">
                                    <ul className="list-inline mb-0">
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                    </ul>
                                  </li>
                                  <li className="list-inline-item fs-13 text-gray-light">
                                    9
                                  </li>
                                </ul>
                              </>
                            ) : null}

                            <ul className="list-group list-group-no-border">
                              <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                <span className="col-sm-5 px-1 fw-600 fs-14 text-heading margin-bottom-auto">
                                  Company Name:
                                </span>
                                <span className="col-sm-7 px-1 text-heading">
                                  <Link
                                    to="/mycompany"
                                    className="text-blue text-uppercase hover-white-text"
                                  >
                                    {profiledata?.company_name}
                                  </Link>
                                </span>

                                {/* <span className="col-sm-5 px-1 fw-600 fs-14 text-heading margin-bottom-auto">
                                  Company
                                </span>
                                <span className="col-sm-7 px-1 text-heading">
                                  <Link
                                    to=""
                                    className="text-blue text-uppercase hover-white-text"
                                  >
                                    {profiledata?.company}
                                  </Link>
                                </span> */}
                              </li>
                              {profiledata?.phone ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      Office Number:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading">
                                      {/* {profiledata?.phone} */}
                                      <CurrencyFormat displayType={'text'} value={profiledata?.phone} format="+(###) #-###-####" mask="_" />
                                      {/* <CurrencyFormat displayType={'text'} value={profileData?.officeNo} format="+61 (###) ###-####" mask="_" /> */}
                                    </span>
                                  </li>
                                </>
                              ) : null}
                              {profiledata?.mobile ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      Mobile Number:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading">
                                     <CurrencyFormat displayType={'text'} value={profiledata?.mobile} format="+(###) #-###-####" mask="_" />
                                    </span>
                                  </li>
                                </>
                              ) : null}
                              {profiledata?.whatsapp ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      Whatsapp Number:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading">
                                      <CurrencyFormat displayType={'text'} value={profiledata?.whatsapp} format="+(###) #-###-####" mask="_" />
                                    </span>
                                  </li>
                                </>
                              ) : null}
                              {profiledata?.website_link ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      Web Link:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading">
                                      <Link
                                        to={profiledata?.website_link}
                                        className="text-blue hover-saffron-text create-button-website"
                                        target="_blank"
                                      >
                                        Click Here
                                      </Link>
                                    </span>
                                  </li>{" "}
                                </>
                              ) : null}
                              {profiledata?.street_address ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading margin-bottom-auto">
                                      Address:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading line-height-1-7">
                                      {profiledata?.street_address}
                                    </span>
                                  </li>
                                </>
                              ) : null}

                              {profiledata?.social ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row mb-0 mt-3  p-0 ">
                                    <ul className="col-sm-10 list-inline text-gray-lighter m-0 px-1 text-align-end">
                                      <li className="list-inline-item m-0">
                                        <Link
                                          className="w-32px h-32 rounded hover-saffron bg-blue  text-white  d-flex align-items-center justify-content-center border "
                                          to=""
                                        >
                                          <BsTwitter />
                                        </Link>
                                      </li>
                                      <li className="list-inline-item mr-0 ml-2">
                                        <Link
                                          className="w-32px h-32 rounded hover-saffron bg-blue  text-white  d-flex align-items-center justify-content-center border "
                                          to=""
                                        >
                                          <BsFacebook />
                                        </Link>
                                      </li>
                                      <li className="list-inline-item mr-0 ml-2">
                                        <Link
                                          className="w-32px h-32 rounded hover-saffron bg-blue  text-white  d-flex align-items-center justify-content-center border "
                                          to=""
                                        >
                                          <BsInstagram />
                                        </Link>
                                      </li>
                                    </ul>
                                  </li>{" "}
                                </>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-4 mt-20">
                    <div className="card border-0">
                      <div className="card-body p-6">
                      <div className=" d-block text-center  ">
                      <Link className="py-3 bg-blue text-white px-1">
                        I am Interested
                      </Link>
                    </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="form-group mb-2 position-add-validate-symbol">
                            <input
                              type="text"
                              className="form-control form-control-lg border-0"
                              placeholder="Your Name"
                              name="name"
                              {...register("name", { required: true })}
                              onChange={handleLead}
                            />
                            <span className="asterisk_input"></span>
                          </div>
                          <div className="form-group mb-2 position-add-validate-symbol">
                            <input
                              type="email"
                              className="form-control form-control-lg border-0"
                              placeholder="Your Email"
                              name="email"
                              {...register("email", { required: true })}
                              onChange={handleLead}
                            />
                            <span className="asterisk_input"></span>
                          </div>
                          <div className="form-group mb-2">
                            <input
                              type="text"
                              className="form-control form-control-lg border-0"
                              placeholder="Your Mobile Number"
                              name="mobile"
                              {...register("mobile")}
                              onChange={handleLead}
                              maxLength={10}
                            />
                          </div>
                          <div className="form-group mb-4">
                            <textarea
                              className="form-control form-control-lg border-0"
                              rows="7"
                              placeholder="Message"
                              name="msg"
                              {...register("msg")}
                              onChange={handleLead}
                            ></textarea>
                          </div>
                          <button
                            type="submit"
                            className="btn bg-blue text-white hover-saffron btn-lg btn-block"
                          >
                            Send Message
                          </button>
                        </form>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </section>
            <section className="pb-11">
              <div className="container">
                <div className="collapse-tabs mb-10">
                  <ul
                    className="nav nav-tabs text-uppercase d-md-inline-flex agent-details-tabs"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        href="#overview"
                        className=" text-blue nav-link active  fs-13 padding-r-5"
                        data-bs-toggle="tab"
                        role="tab"
                      >
                        Overview
                      </a>
                    </li>
                    <>
                      <li className="nav-item ml-0">
                        <a
                          href="#listing"
                          className="nav-link text-blue fs-13 padding-r-5"
                          data-bs-toggle="tab"
                          role="tab"
                        >
                          Portfolio
                        </a>
                      </li>
                    </>
                  </ul>
                  <div className="tab-content shadow-none py-7 px-6 bg-white">
                    <div
                      className="tab-pane tab-pane-parent fade show active"
                      id="overview"
                      role="tabpanel"
                    >
                      <div className="card border-0 bg-transparent">
                        <div className="card-body p-0">
                          <h2 className="card-title lh-15 mb-1 text-dark">
                            {profiledata?.first_name?.toUpperCase()}{" "}
                            {profiledata?.last_name?.toUpperCase()}
                          </h2>
                          <p className="card-text lh-214 mb-3 fs-20">
                            {profiledata?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane tab-pane-parent fade"
                      id="listing"
                      role="tabpanel"
                    >
                      <div className="card border-0 bg-transparent">
                        <div className="card-body p-0">
                          <h2 className="card-title fs-22 lh-15 mb-3 text-dark">
                            Our Properties Portfolio
                          </h2>
                          <ul
                            className="nav nav-pills text-capitalize nav-pills-01 mx-n5"
                            role="tablist"
                          >
                            <li className="nav-item px-3 px-sm-6 py-1 padding-r-4">
                              <a
                                href="#all"
                                className="nav-link active shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                                data-bs-toggle="pill"
                                role="tab"
                              >
                                Images
                              </a>
                            </li>
                            <li className="nav-item ml-0 px-3 px-sm-6 py-1 padding-r-4">
                              <a
                                href="#newProject"
                                className="nav-link shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                                data-bs-toggle="pill"
                                role="tab"
                              >
                                Videos
                              </a>
                            </li>
                            <li className="nav-item ml-0 px-3 px-sm-6 py-1 padding-r-4">
                              <a
                                href="#sale"
                                className="nav-link shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                                data-bs-toggle="pill"
                                role="tab"
                              >
                                Brochure
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content shadow-none pt-5 pb-0 px-0 bg-white">
                            <div
                              className="tab-pane nested-tab-pane fade show active"
                              id="all"
                              role="tabpanel"
                            >
                              {/*  <div className="row"> */}
                              <div className="row">
                                {allImages.map((item, index) =>
                                  item?.image ? (
                                    <div className="col-lg-4 mb-5" key={index} onClick={() =>
                                      handleImageClick(item?.image)
                                    }>

                                      <div className="border-1 px-2 py-2 shadow-hover-xs-2 bg-hover-white">
                                        {/* <div className="media"> */}
                                        <div className="w-100 mr-4 position-relative">
                                          <img
                                            src={item?.image}
                                            alt="Explore Old Barcelon"
                                            className="img-fluid"
                                            style={{
                                              height: "200px",
                                              width: "340px",
                                            }}
                                          />
                                        </div>
                                        {item?.txt ? (<div className="media-body">
                                          <h4 className="fs-18 mb-0 lh-1.5 mt-3">
                                            Description: {item?.txt}
                                          </h4>
                                        </div>) : null}
                                        {/* </div> */}
                                      </div>

                                    </div>
                                  ) : null
                                )}
                              </div>
                              <Modal
                                show={showImageModal}
                                onHide={handleCloseImageModal}
                                centered
                                size="xl"
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>PortFolio Image</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {/* Display the video in the modal */}
                                  <img
                                    src={imageUrl}
                                    alt="Explore Old Barcelon"
                                    className="img-fluid"
                                    style={{ width: "100%" }}
                                  />
                                </Modal.Body>
                              </Modal>
                              {/* </div> */}
                            </div>
                            <div
                              className="tab-pane nested-tab-pane fade"
                              id="newProject"
                              role="tabpanel"
                            >
                              <div className="row">
                                {videodata.map((item, index) =>
                                  item?.video ? (
                                    <div className="col-lg-4 mb-5" key={index} onClick={() =>
                                      handleVideoClick(item?.video)
                                    }>
                                      <Link to="">
                                        <div className="border-1 px-2 py-2 shadow-hover-xs-2 bg-hover-white">
                                          {/* <div className="row"> */}
                                          {/* <tr className="shadow-hover-xs-2 bg-hover-white"> */}
                                          {/* <td className="align-middle pt-6 pb-4 px-6"> */}
                                          {/* <div className="media"> */}
                                          <div
                                            className="w-100 mr-4 position-relative"

                                          >
                                            <video
                                              src={item?.video}
                                              alt="Explore Old Barcelon"
                                              controls
                                              // width="100%"
                                              // height="auto"
                                              className="img-fluid"
                                              style={{
                                                height: "200px",
                                                width: "340px",
                                              }}
                                            />
                                          </div>
                                          {item.txt ? (<div className="media-body">
                                            <h4 className="fs-18 mb-0 lh-1.5">
                                              Description:{' '} {item?.txt}
                                            </h4>
                                          </div>) : null}
                                          {/* </div> */}
                                          {/* </td> */}
                                          {/* </tr> */}
                                          {/* </div> */}
                                        </div>
                                      </Link>
                                      <Modal
                                        show={showVideoModal}
                                        onHide={handleCloseVideoModal}
                                        centered
                                        size="xl"
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>Video</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          {/* Display the video in the modal */}
                                          <video
                                            src={videoUrl}
                                            controls
                                            width="100%"
                                            height="auto"
                                          />
                                        </Modal.Body>
                                      </Modal>
                                    </div>
                                  ) : null
                                )}
                              </div>
                            </div>
                            <div
                              className="tab-pane nested-tab-pane fade"
                              id="sale"
                              role="tabpanel"
                            >
                              <div className="row">
                                {brochure.map((item, index) =>
                                  item?.pdf ? (
                                    <div className="col-lg-4 mb-5" key={index}>
                                      {/* <Link to=""> */}
                                      <div className="border-1 px-2 py-2" onClick={() =>
                                        handlePdfIconClick(item?.pdf)
                                      }>
                                        <tr >
                                          <td className="align-middle p-3">
                                            <div
                                            //  className="media"
                                            // onClick={() =>
                                            //   handlePdfIconClick(item?.pdf)
                                            // }
                                            >
                                              <div className="fs-24 text-center mr-4 position-relative" onClick={() =>
                                                handlePdfIconClick(item?.pdf)
                                              }>
                                                <iframe
                                                  src={item?.pdf}
                                                  title="PDF Viewer"

                                                // width=""
                                                // height="200px"
                                                ></iframe>
                                                <div className="pdf_overlayer"></div>
                                                {/* <i className="bi bi-file-earmark-pdf fa-lg"></i> */}
                                              </div>
                                              {item?.txt ? (<div className="media-body">
                                                <h4 className="fs-18 mb-0 lh-1.5">
                                                  Description:{' '}  {item?.txt}
                                                </h4>
                                              </div>) : null}
                                            </div>
                                          </td>
                                        </tr>
                                      </div>
                                      {/* </Link> */}
                                    </div>
                                  ) : null
                                )}
                              </div>

                              {/* Modal */}
                              <Modal
                                show={showPdfModal}
                                onHide={handleClosePdfModal}
                                centered
                                size="xl"
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Brochure</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {/* Display the PDF in the modal */}
                                  <iframe
                                    src={pdfUrl}
                                    title="PDF Viewer"
                                    width="100%"
                                    height="500px"
                                  ></iframe>
                                </Modal.Body>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      {USER_ID?.id ? <Footer /> : null}
    </>
  );
};

export default Profile;
