import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logoimage from '../images/pontis_icon.jpg'
import axiosInstance from "../interceptor/axiosInstance";
import Loader from "../Loader";
import { Logout } from "../auth/Logout";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;

const Header = () => {
  const [profiledata, setprofiledata] = useState([]);
  const [loading, setLoading] = useState(false)
  const user_Id = localStorage.getItem("currentUser_id")

 const getprofiledata = () => {
  // setLoading(true);
  if (user_Id) {
    try {
      axiosInstance
        .get(
          `${API_URL}/account/user/profile/${user_Id}/patch/`
        )
        .then((response) => {
          const profileinfo = response.data;
          
          setprofiledata(profileinfo);
          // setLoading(false);
        })
        .catch((error) => {
          toast.error(' Please Contact Administrator');
          // setLoading(false);
          // Handle the error as needed
        });
    } catch (error) {
      toast.error(' Please Contact Administrator');
      // setLoading(false);
      // Handle the error as needed
    }
  }
};
  useEffect(() => {
    getprofiledata();
  }, [user_Id]);

/* *******************logout handler */
 function handleLogout() {
  Logout();
 }


  return (
    <>
 {loading ? <Loader /> : null}
      <header className="main-header shadow-none shadow-lg-xs-1 bg-carbon position-relative d-none d-xl-block more-zindex-relative">
        <div className="container-fluid">
          <nav className="navbar navbar-light py-0 row no-gutters px-3 px-lg-0">
            <div className="col-md-4 px-0 px-md-6 order-1 order-md-0"></div>
            <div className="col-md-6 d-flex flex-wrap justify-content-md-end order-0 order-md-1">
              <div className="dropdown  border-0 py-3 text-right">
                <a
                  href="#"
                  className="dropdown-toggle text-heading pr-3 pr-sm-6 d-flex align-items-center "
                  data-bs-toggle="dropdown"
                >
                  <div className="mr-4 w-48px dash-image">
                    {profiledata?.profile ? (
                      <img
                        src={profiledata?.profile}
                        alt="Ronald Hunter"
                        className="rounded-circle dashboard-header-image"
                        style={{ width: "45px", height: "45px" }}
                      />
                    ) : (
                      <img
                        src="../../images/dummy-user.png"
                        alt="Ronald Hunter"
                        className="rounded-circle dashboard-header-image"
                        style={{ width: "45px", height: "45px" }}
                      />
                    )}
                  </div>
                  <div className="text-saffron fs-13 font-weight-500 lh-1">
                    {profiledata?.first_name?.toUpperCase()}{" "}
                    {profiledata?.last_name?.toUpperCase()}
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-right w-100 add-left-dropdown-dashboard-header">
                  <Link
                    to="/profile"
                    style={{ color: "inherit" }}
                    className="dropdown-item"
                  >
                    My Profile
                  </Link>
                  <Link
                    to={`/profile/edit/${profiledata?.id}`}
                    style={{ color: "inherit" }}
                    className="dropdown-item"
                  >
                    Edit Profile
                  </Link>
                  <button
                    style={{ cursor: "pointer" }}
                    className="dropdown-item"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              </div>
              <Link to="/" style={{ padding: "20px 35px 0 0" }}>
                <img
                  style={{ width: "40px" }}
                  src={logoimage}
                  alt="Pontis"
                />
              </Link>

              {/* <div className="mt-4">N</div> */}
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
