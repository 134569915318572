import React from "react";
import axiosInstance from "../interceptor/axiosInstance";
import { useEffect } from "react";
import { useState } from "react";
 import { Button } from "react-bootstrap";
 import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import teamimage from "../images/loadlogo.png";
import Loader from "../Loader";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;

const Team = () => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [memberID,setMemberId] = useState();
  const OnhideModal = () => {
    setShow(false);
  }
  const DeleteMember = (id) => {
    setMemberId(id);
    setShow(true)
    
  }

  const [teamdata,setteamdata]=useState([]);

  const getteamdata = () => {
    setLoading(true)
    axiosInstance
      .get(`${API_URL}/account/get/user/root/team/`)
      .then((response) => {
        if (response.data) {
          const teaminfo = response.data;
          setteamdata(teaminfo)
          
        } else {
          
        }
        setLoading(false)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          toast.error(' Please Contact Administrator');
          //  toast.error(error.response?.data?.detail)
          // navigate('/login')
        } else {
          toast.error(' Please Contact Administrator');
        }
        setLoading(false)
      });
  };

  useEffect(() => {
    getteamdata();
  }, []);

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.delete(
        `${API_URL}/account/get/teams/update/delete/${memberID}/teamuser/`
      );

      if (response.status === 204) {
        // The delete request was successful (status code 204)
        // You can perform any further actions here, e.g., update the UI
        setLoading(false);
      } else {
        // Handle other status codes or errors as needed
       
      }
    } catch (error) {
      // Handle network errors or exceptions
      toast.error(' Please Contact Administrator');
    }
    OnhideModal()
    setMemberId("");
    getteamdata();
    toast.success("Member Removed Successfully ");
  };

  return (
    <>
    {
        loading ? <Loader /> : null
      }
      <div className="agent-row margin-re-top">
        <div className="row">
          {teamdata?.map((item, Index) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-3 mb-6" key={Index}>
                <div className="card shadow-hover-xs-4 agent-3 remove-bg height-445-myagents">
                  <div className="card-header text-center pt-6 pb-3 bg-transparent text-center">
                    {item?.profile ? (
                    <img
                      src={item?.profile}
                      alt="Max Kordek"
                      className="images-get-data"
                    />):
                   ( <img src={teamimage} alt="Max Kordek" className="images-get-data"/>)}

                    <a className="d-block fs-16 lh-2 text-dark mb-0 font-weight-500 hover-saffron-text">
                      {item?.first_name.toUpperCase()} {item?.last_name.toUpperCase()}
                    </a>
                    <p className="mb-2">{item?.user_role}</p>
                  </div>
                  <div className="card-body text-center pt-2  px-0">
                    <a href={`mailto:${item?.email}`} className="text-body">
                      {item?.email}
                    </a>
                    <a
                      href={`tel:${item?.office}`}
                      className="text-heading font-weight-600 d-block mb-3"
                    >
                      Phone: {item?.phone}
                    </a>
                    {item?.mobile?.length ? (
                      <a
                        href={`tel:${item?.mobile}`}
                        className="text-heading font-weight-600 d-block mb-3"
                      >
                        Mobile: {item?.mobile}
                      </a>
                    ) : null}
                  </div>
                  <div className="card-footer px-0 text-center  border-0">
                   
                    <button
                      className="btn btn-danger"
                        onClick={() => DeleteMember(item?.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
            <Modal className="modal-add-contact"
            show={show} >
            <Modal.Body>
              <h3 className="submit-property-modal-heading">Are you sure to delete this Team Member?</h3>
            </Modal.Body>

            <Modal.Footer>
              <Button 
               onClick={() => handleDelete()}
              >
                Yes
              </Button>

              <Button onClick={() => OnhideModal()}>
                No
              </Button>
            </Modal.Footer>
          </Modal>

          
        </div>
        <Link to="/team/add">
          <button
            type="button"
            className="btn bg-blue text-white hover-saffron"
          >
            Add New
          </button>
        </Link>
      </div>
    </>
  );
};

export default Team;
