import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import axiosInstance from "../interceptor/axiosInstance";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

const StatLead = () => {
  moment.locale("en");
  const [propertyId, setpropertyId] = useState("");

  const [loading, setLoading] = useState("");
  const [fliterData, setFliterData] = useState([]);

  /* ****************api for property list ************* */

  const getAllProperties = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/properties/user/get/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setFliterData(responseData);
        setLoading(false);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  useEffect(() => {
    getAllProperties();
  }, []);

  // const navigate = useNavigate();

  // const handleNavigation = (name) => {
 
  //   const stateData = { name:name };
  //   navigate('/stats', { state: stateData });
  // }

  return (
    <>
      {loading ? <Loader /> : null}
      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="d-flex flex-wrap flex-md-nowrap mb-6">
            <div className="mr-0 mr-md-auto">
              <h2 className="mb-0 text-heading fs-22 lh-15">
                Properties Count
                <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">
                  {
                    fliterData.filter(
                      (item) =>
                        item?.count_whatsapp_call > 0 ||
                        item?.count_phone_call > 0
                    )?.length
                  }
                </span>
              </h2>
            </div>
            <div>
              <Link
                to="/stats"
                className="btn bg-blue text-white hover-saffron btn-lg"
              >
                <span>Stats Details</span>
                <span className="d-inline-block ml-1 fs-20 lh-1"></span>
              </Link>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover bg-white border rounded-lg">
              <thead className="thead-sm thead-black bg-blue text-white fs-18">
                <tr>
                  <th scope="col" className="border-top-0 px-6 pt-5 pb-4">
                    Listing title
                  </th>
                  {/* <th scope="col" className="border-top-0 pt-5 pb-4">
                    Date 
                  </th> */}
                  <th
                    scope="col"
                    className="border-top-0 pt-5 pb-4 display-de-none"
                  >
                    Whatsapp Clicks
                  </th>
                  <th scope="col" className="border-top-0 pt-5 pb-4">
                    Call Clicks
                  </th>
                  {/* <th scope="col" className="border-top-0 pt-5 pb-4">
                    More Info
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {fliterData?.length > 0 ? (
                  <>
                    {fliterData
                      .filter(
                        (item) =>
                          item?.count_whatsapp_call > 0 ||
                          item?.count_phone_call > 0
                      )
                      .map((item, index) => {
                        return (
                          <tr
                            className="shadow-hover-xs-2 bg-hover-white"
                            key={index}
                          >
                            <td className="align-middle pt-6 pb-4 px-6">
                              <div className="row">
                                <div className="col-lg-4">
                                  <Link to={`/property/details/${item?.id}`}>
                                    <div className="bg-overlay-gradient-1 bg-hover-overlay-gradient-3 rounded-lg card-img">
                                      <img
                                        style={{ height: "200px" }}
                                        src={item?.upload_featured_image}
                                        alt={item?.property_label_name}
                                      />
                                      <div className="card-img-overlay d-flex flex-column justify-content-between ">
                                        <div>
                                          <span className="badge badge-orange mr-2">
                                            {item?.property_label_name}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div className="col-lg-8 px-5">
                                  <div className="d-flex justify-content-between">
                                    <p
                                      className="mt-3 fs-20 font-weight-bold text-heading mb-0 "
                                      style={{ lineHeight: "1.3" }}
                                    >
                                      {item?.property_price ? (
                                        <>
                                          <span className="text-black font-weight-500 fs-16">
                                            AED{" "}
                                          </span>
                                          <CurrencyFormat
                                            value={item?.property_price}
                                            displayType="text"
                                            thousandSeparator={true}
                                            suffix=".00"
                                          />
                                        </>
                                      ) : (
                                        <>
                                         <span className="text-black font-weight-500 fs-16">
                                            AED{" "}
                                          </span>
                                          <CurrencyFormat
                                            value={item?.lease_price_per_mo}
                                            displayType="text"
                                            thousandSeparator={true}
                                            suffix=".00"
                                          />
                                          <span className="text-gray-light font-weight-500 fs-14">
                                            {" "}
                                            /Mo
                                          </span>
                                        </>
                                      )}
                                    </p>
                                  </div>
                                  <div
                                    className="list-inline-item text-black font-weight-500 fs-16 d-flex align-items-center mr-5 mb-0"
                                    data-toggle="tooltip"
                                    title="Size"
                                  >
                                    {item?.building_type_name}
                                    <span className="text-gray-light ml-2 mr-2">
                                      |
                                    </span>
                                    Area: {item?.property_size}Sq.Ft
                                  </div>
                                  <h2
                                    className="mt-1"
                                    style={{ lineHeight: "0.5" }}
                                  >
                                    <p className="fs-16 text-dark">
                                      {item?.property_name}
                                    </p>
                                  </h2>

                                  <p
                                    className="text-blue  mb-2 "
                                    style={{ lineHeight: "1.7" }}
                                  >
                                    <img
                                      src="../images/locations.png"
                                      className="icon icon-bedroom fs-18  mr-1 width-32 mr-2"
                                      alt="location"
                                    />
                                    {item?.street_address}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="align-middle display-de-none">
                              <span className="badge text-capitalize font-weight-normal fs-18 badge-blue px-2">
                                {item?.count_whatsapp_call > 0
                                  ? item?.count_whatsapp_call
                                  : null}
                              </span>
                            </td>
                            <td className="align-middle display-de-none">
                              <span className="badge text-capitalize font-weight-normal fs-18 badge-orange px-2">
                                {item?.count_phone_call > 0
                                  ? item?.count_phone_call
                                  : null}
                              </span>
                            </td>
                            {/* <td className="align-middle display-de-none">
                              <span className="badge text-capitalize font-weight-normal fs-24 text-dark px-2">
                                <Link
                                  to="/stats"
                                  state={{ buildingname: item?.property_name }}
                                >
                                  <i className="bi bi-info-circle"></i>
                                </Link>
                              </span>
                            </td> */}
                          </tr>
                        );
                      })}
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
};

export default StatLead;
