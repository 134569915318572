import React from 'react'
import { Link } from 'react-router-dom'

const Success = () => {
  
  return (
    <div className="form-body">
      <div className="website-logo">
        <Link to="/">
          <div className="logo">
            <img
              className="logo-size"
              src="../../images/pontis_icon.jpg"
              style={{ width: "150px" }}
            />
          </div>
        </Link>
      </div>
      <div className="img-holder">
        <div
          className="bg"
          style={{ backgroundImage: "url(../../images/skyline-2.png)" }}
        ></div>
      </div>
      <div className="form-holder">
        <div className="form-content fixed-in-mobile">
          <div className="form-items">
            <h2 className='text-blue'>
              Payment Successfull<i class="bi bi-check-circle-fill ml-5 fs"></i>
              {/* <a href="/">
                <span className="pl-name">Dirham Per Foot.</span>
              </a> */}
            </h2>
            
            <div className="Submit-button-section-login">
                <Link to="/">
              <button
                // onClick={handleLogin}
                className="btn text-white bg-blue hover-saffron btn-lg btn-block"
              >
                Continue Shopping
              </button>
              </Link>
            </div>
            
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Success