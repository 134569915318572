import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../interceptor/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../Loader";

function TeamAdd() {
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImages] = useState("");
  const [imageUrl,setImageUrl]=useState("");
  const [value, setValue] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone: "",
    mobile: "",
    user_role: "",
    description:"",
  });

  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const ImageHandler = (e) => {
    const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(
      2
  );
  if (size > 50) {
      toast.error("Please select image size less than 50 MB");
      return;
  }
  setImageUrl(URL.createObjectURL(e?.target?.files[0]));
  setSelectedImages(e?.target?.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("first_name", value.first_name);
    formData.append("last_name", value.last_name);
    formData.append("email", value.email);
    formData.append("password", value.password);
    formData.append("phone", value.phone);
    formData.append("mobile", value.mobile);
    formData.append("user_role", value.user_role);
     formData.append("profile",selectedImage);
     formData.append("description",value.description);
   

    try {
      const response = await axiosInstance.post(
        `${API_URL}/account/create/user/team/`,
        formData
      );
      setLoading(false);
      navigate("/team");
      toast.success("Member added successfully");
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  

  return (
    <>
      {loading ? <Loader /> : null}
      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 my-profile">
          <div className="mb-6 margin-re-top">
            <h2 className="mb-0 text-heading fs-22 lh-15">Add Team Member</h2>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="card bg-transparent border-0">
              <div className="card-body py-4 py-md-0 px-0">
                <div className="card mb-6">
                  <div className="card-body p-6">
                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                      Team Member Add
                    </h3>

                    <div className="form-group mt-5"></div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label
                            htmlFor="first_name"
                            className="text-heading margin-b-0"
                          >
                            First Name
                            <span className="star-mandatoryfield">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg border-0"
                            id="first_name"
                            name="first_name"
                            onChange={(e) =>
                              setValue({ ...value, first_name: e.target.value })
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label
                            htmlFor="last_name"
                            className="text-heading margin-b-0"
                          >
                            Last Name
                            <span className="star-mandatoryfield">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg border-0"
                            id="last_name"
                            name="last_name"
                            onChange={(e) =>
                              setValue({ ...value, last_name: e.target.value })
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label
                            htmlFor="email"
                            className="text-heading margin-b-0"
                          >
                            Email
                            <span className="star-mandatoryfield">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg border-0"
                            id="email"
                            name="email"
                            onChange={(e) =>
                              setValue({ ...value, email: e.target.value })
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label
                            htmlFor="password"
                            className="text-heading margin-b-0"
                          >
                            Password
                            <span className="star-mandatoryfield">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control form-control-lg border-0"
                            id="password"
                            name="password"
                            onChange={(e) =>
                              setValue({ ...value, password: e.target.value })
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label
                            htmlFor="phone"
                            className="text-heading margin-b-0"
                          >
                            OfficeNo
                            {/* <span className="star-mandatoryfield">*</span> */}
                          </label>
                          <input
                            type="tel"
                            maxlength="10"
                            minlength="10"
                            className="form-control form-control-lg border-0"
                            id="phone"
                            name="phone"
                            onChange={(e) =>
                              setValue({ ...value, phone: e.target.value })
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label
                            htmlFor="mobile"
                            className="text-heading margin-b-0"
                          >
                            MobileNo
                            {/* <span className="star-mandatoryfield">*</span> */}
                          </label>
                          <input
                            type="text"
                            maxlength="10"
                            minlength="10"
                            className="form-control form-control-lg border-0"
                            id="mobile"
                            name="mobile"
                            onChange={(e) =>
                              setValue({ ...value, mobile: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label
                            htmlFor="user_role"
                            className="text-heading margin-b-0"
                          >
                            Designation
                            <span className="star-mandatoryfield">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg border-0"
                            id="user_role"
                            name="user_role"
                            onChange={(e) =>
                              setValue({ ...value, user_role: e.target.value })
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label
                            htmlFor="user_role"
                            className="text-heading margin-b-0"
                          >
                            Description
                            <span className="star-mandatoryfield">*</span>
                          </label>
                          <textarea
                            type="text"
                            className="form-control form-control-lg border-0"
                            id="description"
                            name="description"
                            onChange={(e) =>
                              setValue({ ...value, description: e.target.value })
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <div className="edit-prfl btn-sub">
                            Upload Image
                            <input
                              type="file"
                              className="input-file-profile"
                              multiple
                              accept="image/*"
                              onChange={(e) => ImageHandler(e)}
                            ></input>
                          </div>
                        </div>
                      </div>
                      {selectedImage ? (
                        <img
                          
                          src={imageUrl}
                          alt={`Image`}
                          style={{
                            width: "150px",
                            height: "150px",
                            marginRight: "10px",
                          }}
                        />
                      ):null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="center-button mb-5">
                <button
                  type="submit"
                  className="btn btn-lg bg-blue text-white hover-saffron next-button mb-10 c-padding f-right"
                >
                  Add Team
                </button>
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
}

export default TeamAdd;
